import ReactGA from 'react-ga';
import {
  getDeviceId,
  flattenObject,
} from './hb-redux-store/util/miscellaneousServices';
import { getDeviceType } from './hb-redux-store/util/DeviceDetectionService';

const initGA = trackingId => {
  console.log('Initializing GA with: ', trackingId);
  ReactGA.initialize(trackingId);
};

const pageView = location => {
  // console.log('Setting up page: ', location);
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const gtmProps = {
  deviceId: getDeviceId(),
  deviceType: getDeviceType(),
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  adWordsId: process.env.REACT_APP_AD_WORDS_ID,
  gtmId: process.env.REACT_APP_GTM_ID,
};

const generalPayload = {
  deviceId: getDeviceId(),
  appEnvironment: process.env.REACT_APP_ENV,
};

const setAnalyticsLaunchData = launchInfo => {
  gtmProps.isLoggedIn = !!launchInfo.loggedIn;
  if (launchInfo.customerFullName) gtmProps.name = launchInfo.customerFullName;
  if (launchInfo.email) gtmProps.email = launchInfo.email;
  if (launchInfo.mobile) gtmProps.mobile = launchInfo.mobile;
  if (launchInfo.customerType)
    generalPayload.customerType = launchInfo.customerType;
};

const getGtmProps = () => ({
  ...gtmProps,
  deviceId: getDeviceId(),
  deviceType: getDeviceType(),
});

const getDataLayer = () => window?.dataLayer || [];

// const initGTM = () => {
//   const tagManagerArgs = getGtmProps();
//   TagManager.initialize(tagManagerArgs);
// };

const setDefaultGAValues = data => {
  const { category = '', label = '', action = '', value = '' } = data;
  return { category, label, action, value };
};

const trackGtmEvent = ({ eventName, eventData }) => {
  console.log('GTM_props: Device type: ', getGtmProps().deviceType);
  const data = {
    ...getGtmProps(),
    eventName,
    event: eventName,
    eventType: 'customEvent',
    ...setDefaultGAValues(eventData),
    ...eventData,
  };
  // data.events = { event: eventName, eventType: 'customEvent', ...eventData };
  const gtmDataLayer = getDataLayer();
  gtmDataLayer.push(data);
  // TagManager.initialize(data);
};

const createCleverTapPayload = payload => flattenObject(payload);

const sendCleverTapEvent = (eventName, eventData = {}) => {
  const { clevertap = null } = window;
  const completePayload = {
    ...eventData,
    ...generalPayload,
    deviceType: getDeviceType(),
  };
  const cleverTapPayload = createCleverTapPayload(completePayload);
  if (clevertap) clevertap.event.push(eventName, cleverTapPayload);
};

const sendCleverTapUserDetails = userDetails => {
  const { clevertap = null } = window;
  if (clevertap) clevertap.onUserLogin.push(userDetails);
};

export default {
  initGA,
  pageView,
  // initGTM,
  trackGtmEvent,
  setAnalyticsLaunchData,
  clevertap: { sendCleverTapEvent, sendCleverTapUserDetails },
};
