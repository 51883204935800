import facebookAnalytics from 'hb-redux-store/analytics/facebook';
import firebaseAnalytics from 'hb-redux-store/analytics/firebase';
import cleverTapAnalytics from 'hb-redux-store/analytics/clevertap';
import config from 'appConfig';

/*
Pirority Levels:
0 : Off
1 : High
2 : Medium
3 : Low
*/

const getGenericPayload = () => {
  const {codepushAppVersion: appVersion} = config
  let genericPayload = {};
  if (config.isApp) {
    genericPayload = {
      'device-id': config.getDeviceId(),
      'device-type': config.getDeviceType(),
      'client-id': 'app/1.0',
      'app-version-code':
        config?.getBuildNumber() || '',
      'app-version-name':
      appVersion || config?.getVersion() || '',
      'api-version': '1',
      'base-url': config.ENV.API_URL,
      'app-environment': config.ENV.APP_ENV,
      platform: config.os,
    };
  }
  return genericPayload;
};

export const sendAnalytics = ({
  key = '',
  payload = {},
  facebookPriority = 0,
  firebasePriority = 0,
  clevertapPriority = 0,
  ...rest
}) => {
  const completePayload = {
    ...getGenericPayload(),
    ...payload,
  };
  if (config?.isApp) {
    try {
      if (!!facebookPriority) {
        facebookAnalytics({key, completePayload, facebookPriority, ...rest});
      }
      if (!!firebasePriority) {
        firebaseAnalytics({key, completePayload, firebasePriority, ...rest});
      }
      if (!!clevertapPriority) {
        cleverTapAnalytics({key, completePayload, clevertapPriority, ...rest});
      }
    } catch (e) {
      console.error('Error in Analytics', e);
      // TO-DO Sentry Event after integration
    }
  }
};
