import {call, put, select} from 'redux-saga/effects';
import config from 'appConfig';
import {
  SET_CART_LOADING,
  SET_CART,
  SET_CART_QTY_COUNT,
  SET_PROMO_CODE,
  SET_PROMO_LIST,
  SET_REFERRAL_CODE,
  UPDATE_SCRATCH_CARD,
  SET_CART_SKUS,
  SET_CART_SINGLE_SKU,
} from './cart.action';
import {
  getPromoListApi,
  postAddItemApi,
  postMergeOrFlushCartApi,
  postPromoCodeApi,
  postReferralCodeApi,
  postRemoveItemApi,
  removePromoCodeApi,
  removeReferralCodeApi,
  removeSoldoutItemsApi,
  showCartApi,
  scratchedApi,
} from './cart.api';
import {ATC_VIBRATE_PATTERN} from '../../util/constantServices';
import {SET_NOTIFICATION_MSG} from '../../initial.action';
import {
  SET_MERGE_CART_ON_LOGIN_MODAL,
  SET_NON_SERVICEABLE_MODAL,
} from '../modal/modal.action';

import {sendAnalytics} from 'hb-redux-store/analytics';
import { selectInitialData } from 'hb-redux-store/initial.selectors';
import { SET_MEMBERSHIP_BANNER_DETAIL } from '../halalboxPrime/hbPrime.action';

const vibrate = pattern => {
  if (window.navigator?.vibrate) {
    window.navigator.vibrate(pattern);
  }
};

const {trackGtmEvent = () => {}, clevertap = null} = config;

export function* showCartSaga(payload) {
  try {
    const {cb} = payload?.data || {};
    yield put({type: SET_CART_LOADING, data: true});
    const {data} = yield call(showCartApi);
    const cartSkus = {};
    data.cartItems?.length &&
      data.cartItems.map(item => {
        if (item.sku) cartSkus[item.sku] = item.inCartQty;
      });
    const {isMembershipAdded } = data?.membershipDetails;
    yield put({type: SET_CART, data});
    yield put({type: SET_CART_QTY_COUNT, data: data.totalCartQuantity});
    yield put({type: SET_CART_SKUS, data: cartSkus});
    if(!isMembershipAdded) yield put({type: SET_MEMBERSHIP_BANNER_DETAIL, data: data?.membershipDetails || {}});
    cb && cb(data);
    return data;
  } catch (e) {
    yield put({type: SET_CART_LOADING, data: false});
    console.log('Err@ showCartSaga: ', e);
    let errorMessage = '';
    if (e.data?.description || e.data?.error?.errorMessage) {
      errorMessage =
        e.data?.description ||
        e.data?.error?.errorMessage ||
        'Unable to Fetch Cart';
    }
    if (errorMessage === 'Please select a location.') {
      yield put({type: SET_CART, data: {cartItems: []}});
    }
    // SPECIAL CASE WHERE CART IS BREAKING:
    // This should not happen but still happening so handling it.
    if (
      e.data?.description === 'null' &&
      e.data?.type === 'error' &&
      e.data?.uiType === 'text'
    ) {
      yield put({type: SET_CART, data: {cartItems: []}});
    }
  }
}
//
// const _getSkuAndCartQuantity = ({ sku }, { cartItems }) => {
//   let cartQtyCount = 0, skuCount = 0;
//   cartItems.map(item => {
//     cartQtyCount += item.inCartQty;
//     if (item.sku === sku) {
//       skuCount = item.inCartQty;
//     }
//   });
//   return { cartQtyCount, skuCount };
// };

export function* postAddItemSaga({data}) {
  try {
    const {launchInfo} = yield select(selectInitialData);
    const {data: resp} = yield call(postAddItemApi, data);
    const {totalCartQuantity: cartQtyCount, itemQuantity: skuCount} = resp;
    yield put({type: SET_CART_QTY_COUNT, data: cartQtyCount});
    if (data.isCart) {
      yield call(showCartSaga);
    } else {
      const cartSkus = {};
      if (data.sku) cartSkus[data.sku] = skuCount;
      yield put({type: SET_CART_SINGLE_SKU, data: cartSkus});
    }
    if (!config.isApp) {
      window?.fbq && window.fbq('track', 'AddToCart');
      // const gtmDataLayer = window?.dataLayer || [];
      // const atcData = {
      //   deviceId: config.getDeviceId(),
      //   event: 'addToCart',
      //   sku: data.sku,
      // };
      // gtmDataLayer.push(atcData);
      const gtmData = {
        eventName: 'addToCart',
        eventData: {
          category: 'add_to_cart',
          action: data.isCart ? 'cart' : 'others',
          label: data.sku,
          sku: data.sku,
        },
      };
      trackGtmEvent(gtmData);
      // <!-- Event snippet for Add to cart conversion page
      // In your html page, add the snippet and
      // call gtag_report_conversion when someone
      // clicks on the chosen link or button. -->
      function gtag_report_atc_conversion(url) {
        const noOp = () => {};
        const gtag = window?.gtag || noOp;
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
          send_to: '%REACT_APP_AD_WORDS_ID%/8egeCMWLtoACENzH5KEC',
          value: 1.0,
          currency: 'INR',
          event_callback: callback
        });
        return false;
      };
      gtag_report_atc_conversion();

      // Clevertap Analytics
      const key = 'Add To Cart';
      const payload = {
        currency: 'INR',
        productId: data?.productId || data.comboProductId,
        productName: data?.productName || '',
        productCategory: data?.sku || '',
        productPrice:  data?.price,
        productQuantity : data?.quantity || skuCount,
        source: data?.source || ''
      }
      if(clevertap) clevertap.sendCleverTapEvent(key, payload);

    }

    // Analytics
    const analyticsFacebookPayload = {
      fb_content: JSON.stringify(data),
      fb_content_id: data.comboProductId || data.productId,
      fb_content_type: data.comboProductId || data.productId,
      fb_currency: 'INR',
      currency: 'INR',
      price: data?.price,
    };

    const analyticsKey = 'fb_mobile_add_to_cart';

    sendAnalytics({
      key: analyticsKey,
      payload: analyticsFacebookPayload,
      facebookPriority: 1,
    });
    // Analytics
    const key = 'add_to_cart';
    const item = {
      item_id: JSON.stringify(data?.productId || data.comboProductId),
      item_name: JSON.stringify(
        data?.productName || data?.productId || data.comboProductId,
      ),
      item_category: JSON.stringify(
        data?.sku || data?.productId || data.comboProductId,
      ),
      item_variant: JSON.stringify(
        data?.cuts || data?.productId || data.comboProductId,
      ),
      item_brand: JSON.stringify(
        data?.productName || data?.productId || data.comboProductId,
      ),
    };
    // Prepare ecommerce bundle
    const analyticsPayload = {
      currency: 'INR',
      value: parseInt(data?.price || 0, 10),
      items: [item],
    };
    sendAnalytics({
      key,
      payload: analyticsPayload,
      firebasePriority: 1,
    });

    // Clevertap Analytics
    const clevertapPayload = {
      currency: 'INR',
      item_id: data?.productId || data.comboProductId,
      item_name: data?.productName || '',
      item_category: data?.sku || '',
      customer_type: launchInfo?.customerType,
      item_Price:  data?.price,
      item_quantity : data?.quantity || skuCount,
      source: data?.source || ''
    }
    sendAnalytics({
      key: 'Add To Cart',
      payload: clevertapPayload,
      clevertapPriority: 1,
    });

    // console.warn('config.fbLogger', data, contentParams);

    data.successOpCb && data.successOpCb({...data, skuCount});
    vibrate(ATC_VIBRATE_PATTERN);
  } catch (e) {
    console.log('Err@ postAddItemSaga: ', e);
    let errorMessage = '';
    if (e.data?.description || e.data?.error?.errorMessage) {
      errorMessage = e.data?.description || e.data?.error?.errorMessage;
    }
    data.failOpCb && data.failOpCb({errorMessage});
    if (errorMessage === 'Please select a location.') {
      yield put({
        type: SET_NON_SERVICEABLE_MODAL,
        data: {visible: true, source: 'cart'},
      });
    }
  }
}

export function* postRemoveItemSaga({data}) {
  try {
    const {launchInfo} = yield select(selectInitialData);
    const {data: resp} = yield call(postRemoveItemApi, data);
    const {totalCartQuantity: cartQtyCount, itemQuantity: skuCount} = resp;
    yield put({type: SET_CART_QTY_COUNT, data: cartQtyCount});
    if (data.isCart) {
      yield call(showCartSaga);
    } else {
      const cartSkus = {};
      if (data.sku) cartSkus[data.sku] = skuCount;
      yield put({type: SET_CART_SINGLE_SKU, data: cartSkus});
    }
    data.successOpCb && data.successOpCb({...data, skuCount});
    vibrate(ATC_VIBRATE_PATTERN);
    if (!config.isApp) {
      const gtmData = {
        eventName: 'removeFromCart',
        eventData: {
          category: 'remove_from_cart',
          action: data.isCart ? 'cart' : 'others',
          label: data.sku,
        },
      };
      trackGtmEvent(gtmData);

      // Clevertap Analytics
      const key = 'Remove From Cart';
      const payload = {
        currency: 'INR',
        productId: data?.productId || data.comboProductId,
        productName: data?.productName || '',
        productCategory: data?.sku || '',
        productPrice:  data?.price,
        productQuantity : data?.quantity || skuCount,
        source: data?.source || ''
      }
      if(clevertap) clevertap.sendCleverTapEvent(key, payload);

    }
    // Analytics
    const key = 'remove_from_cart';
    const analyticsItem = {
      item_id: data?.productId || data.comboProductId,
      item_name: data?.productName || data?.productId || data.comboProductId,
      item_category: data?.sku || data?.productId || data.comboProductId,
      item_variant: data?.cuts || data?.productId || data.comboProductId,
      item_brand: data?.productName || data?.productId || data.comboProductId,
      price: data?.price,
    };
    const item = {
      ...analyticsItem,
    };
    // Prepare ecommerce bundle
    const payload = {
      currency: 'INR',
      value: data?.price,
      items: [item],
    };
    sendAnalytics({
      key,
      payload,
      firebasePriority: 1,
    });

    // Clevertap Analytics
    const clevertapPayload = {
      currency: 'INR',
      item_id: data?.productId || data.comboProductId,
      item_name: data?.productName || '',
      item_category: data?.sku || '',
      customer_type: launchInfo?.customerType,
      item_Price:  data?.price,
      item_quantity : data?.quantity || skuCount,
      source: data?.source || ''
    }

    sendAnalytics({
      key: 'Remove From Cart',
      payload: clevertapPayload,
      clevertapPriority: 1,
    });

  } catch (e) {
    console.log('Err@ postRemoveItemSaga: ', e);
    data.failOpCb && data.failOpCb(e);
  }
}

export function* postPromoCodeSaga({data}) {
  try {
    const {data: resp} = yield call(postPromoCodeApi, data);
    yield put({type: SET_PROMO_CODE, data: resp});
    let msg = 'Promo applied successfully';
    if (resp.data?.description || resp.data?.message?.description) {
      msg = resp.data?.description || resp.data?.message?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: msg,
      },
    });
    if (!config.isApp) {
      const gtmData = {
        eventName: 'selectPromotion',
        eventData: {
          category: 'checkout > apply_promo',
          action: 'success',
          label: data.promoCode,
        },
      };
      trackGtmEvent(gtmData);
    }
    data.successCb && data.successCb();
  } catch (e) {
    console.log('Err@ postPromoCodeSaga: ', e);
    let errorMsg = 'Error applying promo.';
    if (e.data?.description || e.data?.message?.description) {
      errorMsg = e.data?.description || e.data?.message?.description;
    }
    if (!config.isApp) {
      const gtmData = {
        eventName: 'selectPromotion',
        eventData: {
          category: 'checkout > apply_promo',
          action: 'failure',
          label: data.promoCode,
        },
      };
      trackGtmEvent(gtmData);
    }
    data.errorCb(errorMsg);
  }
}

export function* removePromoCodeSaga({data}) {
  try {
    const {data: resp} = yield call(removePromoCodeApi, data);
    yield put({
      type: SET_PROMO_CODE,
      data: {
        ...resp,
        amountSavedMessage: resp?.amountSavedMessage,
        promoDetails: null,
      },
    });
    let msg = 'Promo removed from cart';
    if (resp.data?.description || resp.data?.message?.description) {
      msg = resp.data?.description || resp.data?.message?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: msg,
      },
    });
  } catch (e) {
    console.log('Err@ removePromoCodeSaga: ', e);
    let errorMsg = 'Error removing promo.';
    if (e.data?.description || e.data?.message?.description) {
      errorMsg = e.data?.description || e.data?.message?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}

export function* postReferralCodeSaga({data}) {
  try {
    const {data: resp} = yield call(postReferralCodeApi, data);
    yield put({
      type: SET_REFERRAL_CODE,
      data: {referralDetails: {appliedReferralCode: data.referralCode}},
    });
    let msg = 'Referral applied successfully';
    if (resp.data?.description || resp.data?.message?.description) {
      msg = resp.data?.description || resp.data?.message?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: msg,
      },
    });
    data.successCb && data.successCb();
  } catch (e) {
    console.log('Err@ postReferralCodeSaga: ', e);
    let errorMsg = 'Error applying referral.';
    if (e.data?.description || e.data?.message?.description) {
      errorMsg = e.data?.description || e.data?.message?.description;
    }
    data.errorCb(errorMsg);
  }
}

export function* removeReferralCodeSaga({data}) {
  try {
    const {data: resp} = yield call(removeReferralCodeApi, data);
    yield put({type: SET_REFERRAL_CODE, data: {referralDetails: null}});
    let msg = 'Referral removed from cart';
    if (resp.data?.description || resp.data?.message?.description) {
      msg = resp.data?.description || resp.data?.message?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: msg,
      },
    });
  } catch (e) {
    console.log('Err@ removeReferralCodeSaga: ', e);
    let errorMsg = 'Error removing referral.';
    if (e.data?.description || e.data?.message?.description) {
      errorMsg = e.data?.description || e.data?.message?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}

export function* postMergeOrFlushCartSaga({data}) {
  try {
    const resp = yield call(postMergeOrFlushCartApi, data);
   // const cartResp = yield call(showCartSaga);
    if (!data?.isBuyNow) {
      yield call(showCartSaga);
    }
    if (resp?.data?.totalCartQuantity) {
      yield put({
        type: SET_CART_QTY_COUNT,
        data: resp?.data?.totalCartQuantity,
      });
    }
    yield put({
      type: SET_MERGE_CART_ON_LOGIN_MODAL,
      data: {mergeCartOnLoginModal: false, source: null},
    });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Cart updated!',
      },
    });
    // if (cartResp?.deliveryAddress && !data.mergeCart) {
    //   const {latitude, longitude} = cartResp.deliveryAddress;
    //   const mapData = {coords: {latitude, longitude}};
    //   data.successCb && data.successCb(mapData);
    // }
    if (data?.successCb) {
      data.successCb();
    }
  } catch (e) {
    console.log('Err@ postMergeOrFlushCartSaga: ', e);
    yield put({
      type: SET_MERGE_CART_ON_LOGIN_MODAL,
      data: {mergeCartOnLoginModal: false, source: null},
    });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Something went wrong, please check your cart.',
      },
    });
  }
}

export function* getPromoListSaga() {
  try {
    const {data} = yield call(getPromoListApi);
    yield put({type: SET_PROMO_LIST, data});
  } catch (e) {
    console.log('Err@ getPromoListSaga: ', e);
  }
}

export function* removeSoldoutItemsSaga({data}) {
  try {
    const {data: resp} = yield call(removeSoldoutItemsApi);
    // console.warn('removeSoldoutItemsSaga', resp);
    if (resp.cartSoldOut) yield call(showCartSaga);
    data.successCb && data.successCb(resp);
  } catch (e) {
    console.log('Err@ removeSoldoutItemsSaga: ', e);
    data.failureCb && data.failureCb();
  }
}

export function* scratchCardSaga({data}) {
  try {
    const {data: resp} = yield call(scratchedApi, data);
    if (resp === 'success') {
      yield put({type: UPDATE_SCRATCH_CARD, data});
    }
  } catch (e) {
    console.log('Err@ scratchCardSaga: ', e);
  }
}
