import config from 'appConfig';
import {getDataFromKey} from 'hb-redux-store/feature/remoteConfig/useRemoteConfig.hooks';

const isInFirebasePriority = firebasePriority =>
  getDataFromKey('shouldAllowFirebaseAnalytics', 'number') >= firebasePriority;

const createFirebasePayload = payload => payload;

const firebaseAnalytics = ({
  firebaseKey = '',
  key,
  completePayload,
  firebasePriority,
}) => {
  if (isInFirebasePriority(firebasePriority)) {
    const firebasePayload = createFirebasePayload(completePayload);
    try {
      const asyncFn = async () =>
        await config
          ?.firebaseAnalytics()
          .logEvent(firebaseKey || key, firebasePayload);
      asyncFn().then();
    } catch (e) {
      console.error('Error in Firebase Analytics', e);
    }
  } else {
    console.warn(
      'Priority Level of Analytics Lower than expected',
      key,
      completePayload,
      firebasePriority,
    );
  }
};

export default firebaseAnalytics;
