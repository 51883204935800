import {
  SET_ORDERS,
  SET_LOADING,
  SET_ORDER_DETAILS,
  SET_ORDER_DETAILS_APP,
  SET_SAVED_PAYMENTS,
  RESET_ORDER_DETAILS,
  SET_FAQS,
  SET_REFERRAL_LIST_LOADING,
  SET_REFERRAL_LIST,
  SET_INVOICE, 
} from './accounts.action';

const initialState = {
  onGoingOrders: null,
  pastOrders: null,
  loading: false,
  savedPayments: null,
  orderDetails: null,
  orderStatus: null,
  faqs: null,
  referralDetails: null,
  referralLoading: false,
  invoiceBody: null,

};

const accountsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_REFERRAL_LIST_LOADING:
      return {
        ...state,
        referralLoading:data,
      }
    case SET_REFERRAL_LIST:
    return {
      ...state,
      referralDetails:data,
    }
    case SET_ORDERS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_ORDER_DETAILS_APP:
    return {
      ...state,
      orderDetails: data,
      loading: false,
    };
    case RESET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: null,
        loading: false,
      }
    case SET_SAVED_PAYMENTS:
      return {
        ...state,
        savedPayments: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_FAQS:
      return {
        ...state,
        faqs: data,
        loading: false,
      };
    case SET_INVOICE:
      return {
        ...state,
        invoiceBody: data,
        loading: false,
      };
    default:
      return state;
  }
};

export default accountsReducer;
