import React, { Component } from 'react';
import Icon from '../../mweb/Icon/Icon';
import SecondaryNav from '../../mweb/SecondaryNav/SecondaryNav';
import * as serviceWorker from '../../../serviceWorker';
import Button from '../Button/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch = (error, errorInfo) => catchFunc(error, errorInfo, this);

  render() {
    if (this.state.errorInfo) {
      return handleError(this);
    }
    // Normally, just render children
    return this.props.children;
  }
}

export const withErrorBoundary = WrappedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch = (error, errorInfo) => catchFunc(error, errorInfo, this);

    render() {
      if (this.state.errorInfo) {
        return handleError(this);
      }
      // Normally, just render children
      return <WrappedComponent {...this.props} />;
    }
  };

const catchFunc = (error, errorInfo, ctx) => {
  // catch errors in any components below and re-render with error message
  ctx.setState({
    error: error,
    errorInfo: errorInfo,
  });
  console.log('In Error Boundary: ', error, errorInfo);
  serviceWorker.unregister();
  // log error messages, etc.
};

const handleError = ctx => (
  // Error path
  <div>
    <SecondaryNav showCart={false} showSearch={false} />
    <div className="cart--empty-cart-icon">
      <Icon class="icon-cart-empty-cart" size={100} />
    </div>
    <div className="cart--empty-cart-oops">OOPS!</div>
    <div className="cart--empty-cart-msg">
      Something went wrong. Please try again or report this if you are
      repeatedly facing this issues.
    </div>
    <div className="cart--empty-cart-cta">
      <Button
        className="button button-primary full-width"
        onClick={() => (window.location.href = '/')}
      >
        Explore Products
      </Button>
    </div>
  </div>
);

export default ErrorBoundary;
