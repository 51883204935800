import {
  SET_LOADING,
  SET_MEMBERSHIP_DETAIL,
  SET_MEMBERSHIP_BANNER_DETAIL
} from './hbPrime.action';

const initialState = {
  loading: false,
  hbPrimeMembershipDetail: {},
  membershipBannerDetail: {}
};

const hbPrimeReducer = (state = initialState, {type, data}) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_MEMBERSHIP_DETAIL:
      return {
        ...state,
        hbPrimeMembershipDetail: data,
        loading: false,
      };
    case SET_MEMBERSHIP_BANNER_DETAIL:
      return {
        ...state,
        membershipBannerDetail: data,
        loading: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default hbPrimeReducer;
