import request from 'hb-redux-store/util/request';
// import { showCartResponse } from 'hb-redux-store/util/mockApiService';

export const showCartApi = () => request.get('/api/v5/showCart');

export const postAddItemApi = data => request.post('/api/cart/v2', data);

export const postRemoveItemApi = data => request.del('/api/cart/v2', data);

export const getPromoListApi = () => request.get('/api/v2/coupons');

export const postPromoCodeApi = ({ promoCode }) => request.post(`/api/coupon/v2/validateCode/${promoCode}`);

export const removePromoCodeApi = ({ promoCode }) => request.post(`/api/coupon/v2/removeCode/${promoCode}`);

export const postReferralCodeApi = ({ referralCode }) => request.post(`/api/referral/apply/${referralCode}`);

export const removeReferralCodeApi = ({ referralCode }) => request.post(`/api/referral/remove/${referralCode}`);

export const postMergeOrFlushCartApi = data => request.post('/api/v3/mergeOrOverrideCart', data);

export const removeSoldoutItemsApi = () => request.post('/api/v3/cart/SoldOut');

export const scratchedApi = data => request.put('/api/coupon/scratched',data);
