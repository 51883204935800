import React from 'react';
import './button.scss';
import ButtonLoader from './ButtonLoader';

const Button = props => {
  // const { type, styles, htmlType } = props;
  let className = props.className || '';
  if (props.large) {
    className += ' large ';
  }
  if (props.disabled && className.indexOf('button-ghost') > -1) {
    return <ButtonLoader />;
  }
  const buttonProps = { ...props, className };
  return <button {...buttonProps}></button>;
};

export default Button;
