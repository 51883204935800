import { getDeviceType } from 'hb-redux-store/util/DeviceDetectionService';
import { getDeviceId } from 'hb-redux-store/util/miscellaneousServices';
import ReactGA from 'react-ga';
import * as deviceDetector from 'react-device-detect';
import LogRocket from 'logrocket';
import { createBrowserHistory } from 'history';
import { setMapping } from './CPMapping';
import {
  getItem as LSGetItem,
  setItem as LSSetItem,
} from './hb-redux-store/util/localStorageService';
import AnalyticsService from './AnalyticsService';
// Temporary Session.
// Using this only for refresh of page on 30 mins expiry.
// If we use localStorage, the value wil persist forever.
// So if a old user comes to website, due to old values the page will refresh again,
// even if he is starting a fresh session.
const temporaryStorage = {};
const getItem = key => temporaryStorage[key] || null;
const setItem = (key, value) => (temporaryStorage[key] = value);
const { trackGtmEvent, setAnalyticsLaunchData, clevertap } = AnalyticsService;

const swConfig = {
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        console.log('SW state changed to: ', event.target.state);
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      console.log('Posting message to skip waiting. ');
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

const hardResetEvent = new Event('HARD_RESET');

const hardResetRedirectEvent = new Event('HARD_RESET_REDIRECT');

const config = {
  isApp: false,
  SOURCE_MAP: true,
  getDeviceId,
  getDeviceType,
  deviceDetection: {
    isMobile: deviceDetector.isMobile,
    isBrowser: deviceDetector.isBrowser,
  },
  deviceDetector,
  ENV: {},
  swConfig,
  LogRocket,
  history: createBrowserHistory(),
  getItem,
  setItem,
  hardResetEvent,
  hardResetRedirectEvent,
  setMapping,
  LSGetItem,
  LSSetItem,
  ReactGA,
  trackGtmEvent,
  setAnalyticsLaunchData,
  firebaseRemoteConfig: {},
  fbLogger: {},
  clevertapAnalytics: {},
  clevertap,
};
export default config;
