import { call, put, all, select } from 'redux-saga/effects';
import {
  SET_BLOGS_WITH_CATEGORY,
  SET_LOADING,
  SET_BLOG_BY_SLUG,
  SET_BLOGS_BY_SEGMENT,
} from './blog.action';
import {
  getBlogBySlugApi,
  getBlogsWithCategoryApi,
  getBlogsWithSegmentApi,
  getRecentBlogsApi
} from './blog.api';
import history from '../../history';
import config from '../../../appConfig';

export function* getBlogsWithCategorySaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const [categoryBlogs, recentPosts] = yield all([
      call(getBlogsWithCategoryApi),
      call(getRecentBlogsApi),
    ]);
    yield put({
      type: SET_BLOGS_WITH_CATEGORY,
      data: {
        allBlogs: categoryBlogs.data,
        recentBlogs: recentPosts.data,
      },
    });
  } catch (e) {
    console.log('Err@ getBlogsWithCategorySaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* getBlogBySlugSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(getBlogBySlugApi, data);
    yield put({ type: SET_BLOG_BY_SLUG, data: resp });
    data.successOpCb && data.successOpCb(resp);
  } catch (e) {
    console.log('Err@ getBlogBySlugSaga: ', e);
    yield put({ type: SET_BLOG_BY_SLUG, data: 'invalid url' });
  }
}

export function* getBlogsWithSegmentSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { subcategoryId } = data;
    const { data: resp } = yield call(getBlogsWithSegmentApi);
    let segment = null;
    resp.map(cat => {
      if (cat?.blogsSubCategoryResponse?.map) {
        cat.blogsSubCategoryResponse.map(subCat => {
          if (subCat.blogSubcategoryId == subcategoryId) {
            segment = subCat;
          }
        });
      }
    });
    yield put({
      type: SET_BLOGS_BY_SEGMENT,
      data: segment,
    });
  } catch (e) {
    console.log('Err@ getBlogsWithSegmentSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}
