import React from 'react';
import PropTypes from 'prop-types';

const Icon = props => {
  const { size, class: type, onClick = () => {}, height, width, src } = props;
  const sizeInPx = size => `${size}px`;
  const heightInPx = height ? sizeInPx(height) : sizeInPx(size);
  const widthInPx = width ? sizeInPx(width) : sizeInPx(size);
  const iconStyle = {
    height: heightInPx,
    width: widthInPx,
    backgroundSize: `${widthInPx} ${heightInPx}`,
  };
  if (src) iconStyle.backgroundImage = `url('${src}')`;
  return <div className={`icon ${type}`} style={iconStyle} onClick={onClick} />;
};

Icon.propTypes = {
  size: PropTypes.number,
  class: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  src: PropTypes.string,
};

export default Icon;
