import React from 'react';
import './secondary-nav.scss';
import history from 'hb-redux-store/history';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Badge from '../Badge/Badge';
import Icon from '../Icon/Icon';

const SecondaryNav = props => {
  const {
    showCart = true,
    showSearch = true,
    capitalize,
    title,
    showTitle = true,
    cartQtyCount,
    source = '',
    transparent = false,
    showFaq = false,
    onBackPress = null,
  } = props;
  const goBack = () => {
    if (onBackPress) {
      onBackPress();
    } else if (history.length === 1) {
      history.push('/');
    } else {
      history.goBack();
    }
  };
  return (
    <div
      className={`secondary-nav justify-content--space-between ${source} ${
        transparent ? 'transparent' : ''
      }`}
    >
      <div className="flex-align-items-center">
        <div className="secondary-nav--back-icon" onClick={goBack}>
          <Icon
            class={source ? 'icon-back-bar-back-white' : 'icon-back-bar-back'}
            size={24}
          />
        </div>
        {title && showTitle && (
          <div
            className={`secondary-nav--${capitalize ? 'capitalize' : 'title'}`}
          >
            {title}
          </div>
        )}
      </div>
      <div>
        {showSearch && (
          <Link to="/search" className="secondary-nav--search-icon">
            <Icon class="icon-back-bar-search" size={24} />
          </Link>
        )}
        {showCart && (
          <Link to="/cart" className="secondary-nav--cart-icon">
            {cartQtyCount > 0 && <Badge text={cartQtyCount} />}
            <Icon class="icon-back-bar-cart" size={24} />
          </Link>
        )}
        {showFaq && (
          <Link to="/help/faqs" className="secondary-nav--cart-icon">
            <Icon class="icon-payment-faqs-white" size={24} />
          </Link>
        )}
      </div>
    </div>
  );
};

SecondaryNav.propTypes = {
  showCart: PropTypes.bool,
  showSearch: PropTypes.bool,
  showFaq: PropTypes.bool,
  capitalize: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  transparent: PropTypes.bool,
  onBackPress: PropTypes.func,
};

const mapStateToProps = ({ cartReducer }) => ({
  cartQtyCount: cartReducer.cartQtyCount,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryNav);
