import { takeEvery } from 'redux-saga/effects';
import {
  GET_CART,
  POST_ADD_ITEM,
  POST_REMOVE_ITEM,
  POST_PROMO_CODE,
  GET_PROMO_LIST,
  POST_MERGE_CART,
  REMOVE_PROMO_CODE,
  REMOVE_SOLDOUT_ITEMS,
  POST_REFERRAL_CODE,
  REMOVE_REFERRAL_CODE,
  SCRATCH_CARD
} from './cart.action';
import {
  showCartSaga,
  postAddItemSaga,
  postRemoveItemSaga,
  getPromoListSaga,
  postPromoCodeSaga,
  postMergeOrFlushCartSaga,
  removePromoCodeSaga,
  removeSoldoutItemsSaga,
  postReferralCodeSaga,
  removeReferralCodeSaga,
  scratchCardSaga
} from './cart.saga';

export const cartSaga = [
  takeEvery(GET_CART, showCartSaga),
  takeEvery(POST_ADD_ITEM, postAddItemSaga),
  takeEvery(POST_REMOVE_ITEM, postRemoveItemSaga),
  takeEvery(GET_PROMO_LIST, getPromoListSaga),
  takeEvery(POST_PROMO_CODE, postPromoCodeSaga),
  takeEvery(POST_MERGE_CART, postMergeOrFlushCartSaga),
  takeEvery(REMOVE_PROMO_CODE, removePromoCodeSaga),
  takeEvery(REMOVE_SOLDOUT_ITEMS, removeSoldoutItemsSaga),
  takeEvery(POST_REFERRAL_CODE, postReferralCodeSaga),
  takeEvery(REMOVE_REFERRAL_CODE, removeReferralCodeSaga),
  takeEvery(SCRATCH_CARD, scratchCardSaga),
];
