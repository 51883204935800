import config from 'appConfig';
import {getDataFromKey} from 'hb-redux-store/feature/remoteConfig/useRemoteConfig.hooks';

const isInFacebookPriority = facebookPriority =>
  getDataFromKey('shouldAllowFacebookAnalytics', 'number') >= facebookPriority;

const createFbPayload = payload => payload;

const facebookAnalytics = ({
  facebookKey = '',
  key,
  completePayload,
  facebookPriority,
  isLogPurchase,
  orderPayableAmount,
  curencyName,
  payload,
}) => {
  if (isInFacebookPriority(facebookPriority)) {
    const fbpayload = createFbPayload(completePayload);
    try {
      config.fbLogger.logEvent(facebookKey || key, fbpayload);
      if (isLogPurchase)
        config.fbLogger.logPurchase(orderPayableAmount, curencyName, payload);
    } catch (e) {
      console.error('Error in Facebook Analytics', e);
    }
  } else {
    console.warn(
      'Priority Level of Analytics Lower than expected',
      key,
      completePayload,
      facebookPriority,
    );
  }
};

export default facebookAnalytics;
