import { takeEvery } from 'redux-saga/effects';
import {
  GET_HOME_PAGE_SECTION,
  POST_USER_LOCATION,
  GET_ADDRESSES_FOR_LOCATION,
  GET_SPECIAL_PAGE_SECTION,
  GET_OFFERS,
  GET_SECTION_DATA,
  GET_OPTIMIZED_HOME_PAGE_SECTION,
  GET_HOMEPAGE_AND_BANNER_DETAIL
} from './home.action';
import {
  getHomePageSectionSaga,
  postUserLocationSaga,
  getAddressesForLocationSaga,
  getSpecialPageSaga,
  getOffersSaga,
  getSectionDataSaga,
  getOptimizedHomePageSectionSaga,
  getHomePageAndBannerDetailSaga
} from './home.saga';

export const homeSaga = [
  takeEvery(GET_HOME_PAGE_SECTION, getHomePageSectionSaga),
  takeEvery(POST_USER_LOCATION, postUserLocationSaga),
  takeEvery(GET_ADDRESSES_FOR_LOCATION, getAddressesForLocationSaga),
  takeEvery(GET_SPECIAL_PAGE_SECTION, getSpecialPageSaga),
  takeEvery(GET_OFFERS, getOffersSaga),
  takeEvery(GET_OPTIMIZED_HOME_PAGE_SECTION, getOptimizedHomePageSectionSaga),
  takeEvery(GET_SECTION_DATA, getSectionDataSaga),
  takeEvery(GET_HOMEPAGE_AND_BANNER_DETAIL, getHomePageAndBannerDetailSaga),
];
