import {takeEvery, all} from 'redux-saga/effects';
import {
  getLaunchCheckSaga,
  mergeLaunchInfoSaga,
  setAppNotificationSaga,
} from './initial.saga';
import {
  GET_LAUNCH_INFO,
  MERGE_LAUNCH_INFO,
  SET_NOTIFICATION_MSG_ANIMATE,
} from './initial.action';
import {loginSaga} from './feature/login/index.saga';
import {productSaga} from './feature/products/index.saga';
import {addressSaga} from './feature/address/index.saga';
import {cartSaga} from './feature/cart/index.saga';
import {paymentSaga} from './feature/checkout/index.saga';
import {homeSaga} from './feature/home/index.saga';
import {accountsSaga} from './feature/accounts/index.saga';
import {BlogSaga} from './feature/blog/index.saga';
import {SubscriptionSaga} from './feature/subscription/index.saga';
import {WalletSaga} from './feature/wallet/index.saga';
import {HalalBoxPointSaga} from './feature/halalboxPoints/index.saga';
import {NotificationsSaga} from './feature/notifications/index.saga';
import {HalalBoxPrimeSaga} from './feature/halalboxPrime/index.saga';

export default function* rootSaga() {
  yield all([
    ...loginSaga,
    ...productSaga,
    ...cartSaga,
    ...paymentSaga,
    ...addressSaga,
    ...homeSaga,
    ...accountsSaga,
    ...BlogSaga,
    ...SubscriptionSaga,
    ...WalletSaga,
    ...HalalBoxPointSaga,
    ...NotificationsSaga,
    ...HalalBoxPrimeSaga,
    takeEvery(GET_LAUNCH_INFO, getLaunchCheckSaga),
    takeEvery(SET_NOTIFICATION_MSG_ANIMATE, setAppNotificationSaga),
    takeEvery(MERGE_LAUNCH_INFO, mergeLaunchInfoSaga),
  ]);
}
