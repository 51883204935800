const prefix = 'WALLET/';
export const SET_LOADING = `${prefix}SET_LOADING`;
export const GET_WALLET = `${prefix}GET_WALLET`;
export const SET_WALLET = `${prefix}SET_WALLET`;
export const ADD_WALLET_RECHARGE = `${prefix}ADD_WALLET_RECHARGE`;
export const GET_RECHARGE_METHODS = `${prefix}GET_RECHARGE_METHODS`;
export const SET_RECHARGE_METHODS = `${prefix}SET_RECHARGE_METHODS`;
export const POST_RECHARGE_WALLET = `${prefix}POST_RECHARGE_WALLET`;
export const POST_PAY_AND_ORDER = `${prefix}POST_PAY_AND_ORDER`;
export const UPDATE_WALLET_TRANSACTION = `${prefix}UPDATE_WALLET_TRANSACTION`;
export const SET_TRANSACTION_HISTORY = `${prefix}SET_TRANSACTION_HISTORY`;

export const setLoading = data => ({type: SET_LOADING, data});

export const getWallet = data => ({
  type: GET_WALLET,
  data,
});

export const walletRecharge = (data, cb) => ({
  type: ADD_WALLET_RECHARGE,
  data,
  cb,
});

export const getRechargeMethods = () => ({type: GET_RECHARGE_METHODS});

export const postRechargeWallet = (
  data,
  razorPayInstance,
  payUInstance,
  cb,
  appFailedCb,
  payUSuccessCb,
  payUFailureCb,
) => ({
  type: POST_RECHARGE_WALLET,
  data,
  razorPayInstance,
  payUInstance,
  cb,
  appFailedCb,
  payUSuccessCb,
  payUFailureCb,
});

export const payAndPlaceOrder = (data, cb, failCb) => ({
  type: POST_PAY_AND_ORDER,
  data,
  cb,
  failCb,
});
