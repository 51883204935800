import { takeEvery } from 'redux-saga/effects';
import {
  POST_LOGOUT,
  POST_EMAIL_LOGIN,
  POST_PHONE_NO_FOR_OTP,
  POST_VERIFY_OTP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_USER_EMAIL,
  POST_VERIFY_CCA_LOGIN,
} from './login.action';
import {
  postLogoutSaga,
  postPhoneNoForOTPSaga,
  postVerifyOTPSaga,
  postEmailLoginSaga,
  postForgotPasswordSaga,
  postResetPasswordSaga,
  getUserEmailSaga,
  postVerifyCCAForOfcSaga,
} from './login.saga';

export const loginSaga = [
  takeEvery(POST_LOGOUT, postLogoutSaga),
  takeEvery(POST_PHONE_NO_FOR_OTP, postPhoneNoForOTPSaga),
  takeEvery(POST_VERIFY_OTP, postVerifyOTPSaga),
  takeEvery(POST_EMAIL_LOGIN, postEmailLoginSaga),
  takeEvery(FORGOT_PASSWORD, postForgotPasswordSaga),
  takeEvery(RESET_PASSWORD, postResetPasswordSaga),
  takeEvery(GET_USER_EMAIL, getUserEmailSaga),
  takeEvery(POST_VERIFY_CCA_LOGIN, postVerifyCCAForOfcSaga),
];
