const prefix = 'SUBSCRIPTION/';
export const GET_CALENDAR = `${prefix}GET_CALENDAR`;
export const SET_CALENDAR = `${prefix}SET_CALENDAR`;
export const GET_PRODUCTS = `${prefix}GET_PRODUCTS`;
export const SET_PRODUCTS = `${prefix}SET_PRODUCTS`;
export const PUT_PRODUCTS_AND_PRICE = `${prefix}PUT_PRODUCTS_AND_PRICE`;
export const RESET_PRODUCTS = `${prefix}RESET_PRODUCTS`;
export const GET_SUMMARY = `${prefix}GET_SUMMARY`;
export const SET_SUMMARY = `${prefix}SET_SUMMARY`;
export const POST_ADD_ITEM = `${prefix}POST_ADD_ITEM`;
export const POST_REMOVE_ITEM = `${prefix}POST_REMOVE_ITEM`;
export const POST_SLOT = `${prefix}POST_SLOT`;
export const SET_SLOT = `${prefix}SET_SLOT`;
export const RESET_SLOT = `${prefix}RESET_SLOT`;
export const GET_PAYMENT_DETAILS = `${prefix}GET_PAYMENT_DETAILS`;
export const SET_PAYMENT_DETAILS = `${prefix}SET_PAYMENT_DETAILS`;
export const POST_PLACE_ORDER = `${prefix}POST_PLACE_ORDER`;
export const GET_SAVED_PAYMENTS = `${prefix}GET_SAVED_PAYMENTS`;
export const SET_SAVED_PAYMENTS = `${prefix}SET_SAVED_PAYMENTS`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const RESET_SUBSCRIPTION = `${prefix}RESET_SUBSCRIPTION`;
export const GET_SUBSCRIPTION_CONFIRMATION = `${prefix}GET_SUBSCRIPTION_CONFIRMATION`;
export const SET_SUBSCRIPTION_CONFIRMATION = `${prefix}SET_SUBSCRIPTION_CONFIRMATION`;
export const PUT_SELECTED_DAY = `${prefix}PUT_SELECTED_DAY`;

export const SET_WEEKS = `${prefix}SET_WEEKS`;

export const getCalendar = () => ({ type: GET_CALENDAR });

export const getProducts = data => ({ type: GET_PRODUCTS, data });

export const setSubscriptionSlot = data => ({ type: POST_SLOT, data });

export const addItem = data => ({ type: POST_ADD_ITEM, data });

export const removeItem = data => ({ type: POST_REMOVE_ITEM, data });

export const getSavedPayments = () => ({ type: GET_SAVED_PAYMENTS });

export const getSummary = data => ({ type: GET_SUMMARY, data });

export const resetDayAndProducts = () => ({ type: RESET_PRODUCTS });

export const getPaymentDetails = () => ({ type: GET_PAYMENT_DETAILS });

export const postPlaceOrder = (
  data,
  razorPayInstance,
  payUInstance,
  cb,
  appFailedCb,
  payUSuccessCb,
  payUFailureCb
) => ({
  type: POST_PLACE_ORDER,
  data,
  razorPayInstance,
  payUInstance,
  cb,
  appFailedCb,
  payUSuccessCb,
  payUFailureCb
});

export const setLoading = data => ({ type: SET_LOADING, data });

export const subscriptionItemsSelector = ({ subscriptionReducer }) => ({
  subscriptionItems: subscriptionReducer.subscriptionItems,
  selectedDay: subscriptionReducer.selectedDay,
  calendar: subscriptionReducer.calendar,
});

export const getSubscriptionConfirmation = data => ({
  type: GET_SUBSCRIPTION_CONFIRMATION,
  data
});

export const resetSelectedSlot = () => ({ type: RESET_SLOT });
