const prefix = 'INITIAL_ACTION/';
export const GET_LAUNCH_INFO = `${prefix}GET_LAUNCH_INFO`;
export const SET_LAUNCH_INFO = `${prefix}SET_LAUNCH_INFO`;
export const SET_LOGGEDIN_INFO = `${prefix}SET_LOGGEDIN_INFO`;
export const MERGE_LAUNCH_INFO = `${prefix}MERGE_LAUNCH_INFO`;

export const GET_APP_SETTINGS = `${prefix}GET_APP_SETTINGS`;
export const SET_APP_SETTINGS = `${prefix}SET_APP_SETTINGS`;
export const SET_NOTIFICATION_MSG = `${prefix}SET_NOTIFICATION_MSG`;
export const SET_NOTIFICATION_MSG_ANIMATE = `${prefix}SET_NOTIFICATION_MSG_ANIMATE`;
export const SET_MAIN_HEADER = `${prefix}SET_MAIN_HEADER`;

export const getLaunchInfo = (cb = null) => ({ type: GET_LAUNCH_INFO, cb });

export const mergeLaunchInfo = (data, cb) => ({ type: MERGE_LAUNCH_INFO, data, cb })

export const setAppNotification = (data) => ({ type: SET_NOTIFICATION_MSG_ANIMATE, data })

export const setMainHeader = data => {
  if (data) {
    document.body.classList.remove('blog-container');
    document.body.classList.add('desktop-container');
    document.head.classList.add('header-container');
  } else {
    document.body.classList.add('blog-container');
    document.body.classList.remove('desktop-container');
    document.head.classList.remove('header-container');
  }
  return { type: SET_MAIN_HEADER, data };
};

// export const setAppNotification = data => ({
//   type: SET_NOTIFICATION_MSG,
//   data: {
//     show: true,
//     message: data
//   }
// });

export const hideAppNotification = () => ({
  type: SET_NOTIFICATION_MSG,
  data: {
    show: false,
    message: ''
  }
});

export const notificationSelector = ({ initialReducer: { notification } }) =>
notification;

export const launchInfoSelector = ({ initialReducer: { launchInfo } }) =>
  launchInfo;
