import {
  SET_LOADING_ADDRESS_LISTING,
  SET_ADDRESS_LISTING,
  RESET_ADDRESS_LISTING
} from './address.action';

const initialState = {
  loadingAddress: false,
  addresses: null
};

const addressReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ADDRESS_LISTING:
      return {
        ...state,
        addresses: data,
        loadingAddress: false
      };
    case SET_LOADING_ADDRESS_LISTING:
      return {
        ...state,
        loadingAddress: data
      };
    
    case RESET_ADDRESS_LISTING:
      return {
        ...initialState,
      }

    default:
      return state;
  }
};

export default addressReducer;
