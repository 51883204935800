const prefix = 'HBPRIME/';
export const SET_LOADING = `${prefix}SET_LOADING`;
export const GET_ALL_MEMBERSHIP_DETAIL = `${prefix}GET_ALL_MEMBERSHIP_DETAIL`;
export const SET_MEMBERSHIP_DETAIL = `${prefix}SET_MEMBERSHIP_DETAIL`;
export const SET_MEMBERSHIP_BANNER_DETAIL = `${prefix}SET_MEMBERSHIP_BANNER_DETAIL`;
export const ADD_MEMBERSHIP_TO_CART = `${prefix}ADD_MEMBERSHIP_TO_CART`;
export const Remove_MEMBERSHIP_TO_CART = `${prefix}Remove_MEMBERSHIP_TO_CART`;

export const getAllMembershipDetail = () => ({
  type: GET_ALL_MEMBERSHIP_DETAIL
});

export const addMembershipTocart = data => ({
  type: ADD_MEMBERSHIP_TO_CART,
  data
});

export const removeMembershipTocart = data => ({
  type: Remove_MEMBERSHIP_TO_CART,
  data
});

export const setMemberShipBannerDetails = data => ({
  type: SET_MEMBERSHIP_BANNER_DETAIL,
  data
});