import request from 'hb-redux-store/util/request';

export const getOrdersApi = () => request.get('/api/v3/orders');

export const getOrderStatusApi = ({ orderNo }) => request.get(`/api/v2/orderStatus/${orderNo}`);

export const getOrderDetailsApi = ({ orderNo }) => request.get(`/api/v4/showOrderDetails/${orderNo}`);

export const putProfileDetailsApi = data => request.put('/api/customer', data);

export const getFaqsApi = () => request.get('/api/helpfaq');

export const getReferralListingApi = () => request.get('/api/getReferralList');

export const trackOrderApi = ({ orderNo }) => request.get(`/api/v2/orderStatus/${orderNo}`);

export const getInvoiceApi = ({ orderNo }) => request.get(`/api/orderInvoice/${orderNo}`);

// export const postReorderApi = ({ orderNo }) => request.post(`/api/v3/reorder/${orderNo}`);
export const postReorderApi = ({ orderNo }) => request.post(`/api/reorder/${orderNo}`);
// export const getInvoiceApi = ({ orderNo }) => request.get(`/api/orderInvoice/20200712G3UW`);
