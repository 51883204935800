import config from 'appConfig';
import {getDataFromKey} from 'hb-redux-store/feature/remoteConfig/useRemoteConfig.hooks';
import {flattenObject} from 'hb-redux-store/util/miscellaneousServices';

const isInCleverTapPriority = clevertapPriority =>
  getDataFromKey('shouldAllowCleverTapAnalytics', 'number') >=
  clevertapPriority;

const createCleverTapPayload = payload => flattenObject(payload);

const cleverTapAnalytics = ({
  clevertapKey = '',
  key,
  completePayload,
  clevertapPriority,
  onUserLogin,
  setLocation,
}) => {
  if (isInCleverTapPriority(clevertapPriority)) {
    const cleverTapPayload = createCleverTapPayload(completePayload);
    const shouldRecordCustomEvent = !onUserLogin || !setLocation;
    try {
      if (shouldRecordCustomEvent)
        config.clevertapAnalytics.recordEvent(
          clevertapKey || key,
          cleverTapPayload,
        );
      if (onUserLogin) config.clevertapAnalytics.onUserLogin(cleverTapPayload);
      if (setLocation)
        config.clevertapAnalytics.setLocation(
          cleverTapPayload?.latitude,
          cleverTapPayload?.longitude,
        );
    } catch (e) {
      console.error('Error in Clever Tap Analytics', e);
    }
  } else {
    console.warn(
      'Priority Level of Clevertap Analytics Lower than expected',
      key,
      completePayload,
      clevertapPriority,
    );
  }
};

export default cleverTapAnalytics;
