const prefix = 'ACCOUNTS/';
export const GET_ORDERS = `${prefix}GET_ORDERS`;
export const SET_ORDERS = `${prefix}SET_ORDERS`;
export const GET_FAQS = `${prefix}GET_FAQS`;
export const SET_FAQS = `${prefix}SET_FAQS`;
export const GET_ORDER_DETAILS = `${prefix}GET_ORDER_DETAILS`;
export const GET_ORDER_STATUS = `${prefix}GET_ORDER_STATUS`;
export const TRACK_ORDER_STATUS = `${prefix}TRACK_ORDER_STATUS`;
export const SET_ORDER_DETAILS = `${prefix}SET_ORDER_DETAILS`;
export const SET_ORDER_DETAILS_APP = `${prefix}SET_ORDER_DETAILS_APP`;
export const GET_SAVED_PAYMENTS = `${prefix}GET_SAVED_PAYMENTS`;
export const SET_SAVED_PAYMENTS = `${prefix}SET_SAVED_PAYMENTS`;
export const PUT_PROFILE_DETAILS = `${prefix}PUT_PROFILE_DETAILS`;
export const SET_PROFILE_DETAILS = `${prefix}SET_PROFILE_DETAILS`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const RESET_ORDER_DETAILS = `${prefix}RESET_ORDER_DETAILS`;

export const GET_REFERRAL_LIST = `${prefix}GET_REFERRAL_LIST`;
export const SET_REFERRAL_LIST = `${prefix}SET_REFERRAL_LIST`;
export const SET_REFERRAL_LIST_LOADING = `${prefix}SET_REFERRAL_LIST_LOADING`;
export const GET_INVOICE = `${prefix}GET_INVOICE`;
export const SET_INVOICE = `${prefix}SET_INVOICE`;
export const POST_REORDER = `${prefix}POST_REORDER`;

export const getOrders = () => ({ type: GET_ORDERS });

export const getOrderDetails = data => ({ type: GET_ORDER_DETAILS, data });

export const getOrderStatus = data =>({ type: GET_ORDER_STATUS, data });

export const trackOrder = data => ({ type: TRACK_ORDER_STATUS, data });

export const resetOrderDetails = () => ({ type: RESET_ORDER_DETAILS });

export const getSavedPayments = () => ({ type: GET_SAVED_PAYMENTS });

export const putProfileDetails = data => ({ type: PUT_PROFILE_DETAILS, data });

export const setLoading = data => ({ type: SET_LOADING, data });

export const getFaqs = () => ({ type: GET_FAQS });

export const getReferralListing = () => ({ type: GET_REFERRAL_LIST });

export const getInvoice = data => ({ type: GET_INVOICE, data });

export const postReorder = (data, cb) => ({ type: POST_REORDER, data, cb });

export const orderDetailsSelector = ({ accountsReducer: { orderDetails } }) =>
orderDetails;
