const prefix = 'HBPOINT/';
export const SET_LOADING = `${prefix}SET_LOADING`;
export const GET_HALALBOX_POINT_DATA = `${prefix}GET_HALALBOX_POINT_DATA`;
export const SET_HALALBOX_POINT_DATA = `${prefix}SET_HALALBOX_POINT_DATA`;
export const GET_HALABOX_EARNING_HISTORY = `${prefix}GET_HALABOX_EARNING_HISTORY`;
export const SET_HALABOX_EARNING_HISTORY = `${prefix}SET_HALABOX_EARNING_HISTORY`;
export const UPDATE_EARNING_HISTORY = `${prefix}UPDATE_EARNING_HISTORY`;
export const RESET_EARNING_HISTORY = `${prefix}RESET_EARNING_HISTORY`;
export const SUBMIT_REFERAL_CODE = `${prefix}SUBMIT_REFERAL_CODE`;

export const setLoading = data => ({ type: SET_LOADING, data });

export const getHbPointData = () => ({
  type: GET_HALALBOX_POINT_DATA
});

export const getHbEarnHistory = data => ({
  type: GET_HALABOX_EARNING_HISTORY,
  data
});

export const resetEarningHistory = () => ({
  type: RESET_EARNING_HISTORY,
});

export const submitReferalCode = (data, cb) => ({
  type: SUBMIT_REFERAL_CODE,
  data,
  cb,
});

