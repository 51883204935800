import appConfig from 'appConfig'
// AsyncStorage.clear();

export const storeData = async (key, value) => {
  const { AsyncStorage = () => {} } = appConfig;
    return new Promise( async (resolve) => {
        try {
          await AsyncStorage.setItem(key, JSON.stringify(value));
          resolve();
        } catch (e) {
          console.warn("Error setting data to async Storage", key, value);
          resolve();
        }
    });
};

export const getData = async (key) => {
  const { AsyncStorage = () => {} } = appConfig;
    return new Promise( async (resolve) => {
        try {
            const value = await AsyncStorage.getItem(key);
            if(value !== null) {
              resolve(JSON.parse(value));
            }
            resolve();
          } catch(e) {
              console.warn("Error getting data from async Storage", key);
              resolve();
          }
    });
}

export const removeData = async (key) => {
  const { AsyncStorage = () => {} } = appConfig;
  try {
    await AsyncStorage.removeItem(key);
  } catch(e) {
    console.warn("Error removing data from async Storage", key);
  }
}
