import request from 'hb-redux-store/util/request';

const pingUrl = process.env.REACT_APP_PING_URL || '/api/ping';
const mockAppSettings ='https://jsonblob.com/api/jsonBlob/2d7363f7-8c5f-11ea-8ee7-89e0be2f030a';

export const getLaunchCheckApi = () => request.get('/api/launchApi');

export const getCPMappingApi = () => request.get('/api/cp-mapping');

export const getPingCheckApi = () => request.get(pingUrl);

// export const getLocationFromCDNApi = data => {
//   const { req } = request;
//   return req.get('https://geolocation-db.com/json/');
// }

