import request from 'hb-redux-store/util/request';

export const getSubscriptionCalendarApi = () =>
  request.get('/api/subscription');

export const getSubscriptionItemsApi = ({
  subscriptionCalendarId,
  subscriptionId,
}) =>
  request.get(
    `/api/subscription/products?subscriptionId=${subscriptionId ||
      ''}&subscriptionCalendarId=${subscriptionCalendarId || ''}`
  );

export const postSubscriptionCalendarIdApi = data =>
  request.post(
    `/api/subscription/${data.subscriptionId}/subscriptionCalendarId`,
    data
  );

export const addSubscriptionItemApi = data =>
  request.post('/api/subscription/product', data);

export const removeSubscriptionItemApi = data =>
  request.del('/api/subscription/product', data);

export const postDeliverySlotApi = data =>
  request.post(`/api/subscription/${data.subscriptionId}/delivery-slot`, data);

export const postPlaceSubscriptionOrderApi = () =>
  request.post(`/api/subscription/orders/new`);

export const postSaveSubscriptionUserPaymentApi = ({
  paymentMode,
  subscriptionNo,
  data,
}) =>
  request.post(
    `/api/subscription/${subscriptionNo}/payment-methods/${paymentMode}`,
    data
  );

export const getSubscriptionSummaryApi = ({ subscriptionNo }) =>
  request.get(`/api/subscription/${subscriptionNo}/summary`);

export const getPaymentMethodsApi = () =>
  request.get('/api/payment/subscription/methods');

export const getPaymentSummaryApi = () =>
  request.get('/api/subscription/details');

export const postVerifySubscriptionPaymentApi = ({ subscriptionNo, data }) =>
  request.post(`/api/subscription/${subscriptionNo}/validate-payment`, data);

export const postVerifySubscriptionPaymentApiPayU = ({
  subscriptionNo,
  data,
  sdkCallBackStatus,
}) =>
  request.post(
    `/api/subscription/${subscriptionNo}/validate-payment-payu-app?sdkCallBackStatus=${sdkCallBackStatus}`,
    data
  );

export const getSubscriptionConfirmationApi = ({ subscriptionNo }) =>
  request.get(`/api/subscription-confirmation/${subscriptionNo}`);
