import request from 'hb-redux-store/util/request';

export const getHomePageSectionApi = () => request.get('/api/page/homepage');

export const postUserLocationApi = data =>
  request.post('/api/validateSelectedLocation', data);

export const getAddressesForLocationApi = () =>
  request.get('/api/getAddressesWithAddressType');

export const getSpecialPageSectionApi = ({ pageName }) =>
  request.get(`/api/page/${pageName}`);

export const getOffersApi = () => request.get('/api/offers');
