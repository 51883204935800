import {
  SET_CART,
  SET_CART_LOADING,
  SET_CART_QTY_COUNT,
  RESET_CART,
  SET_PROMO_CODE,
  SET_PROMO_LIST,
  SET_REFERRAL_CODE,
  UPDATE_SCRATCH_CARD,
  SET_CART_SKUS,
  RESET_CART_SKUS,
  SET_CART_SINGLE_SKU,
} from './cart.action';

const initialState = {
  cart: null,
  cartLoading: false,
  completeAddressesList: [],
  // cartQtyCount: getRandomInteger(1, 0.65),
  cartQtyCount: 0,
  selectedAddress: null,
  promoList: null,
  cartSkus: {},
};

const cartReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CART:
      return {
        ...state,
        cart: data,
        selectedAddress: data.deliveryAddress,
        completeAddressesList: data.completeAddressesList,
        cartLoading: false,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        cart: {
          ...state.cart,
          ...data,
        }
      };
    case SET_REFERRAL_CODE:
      return {
        ...state,
        cart: {
          ...state.cart,
          ...data,
        }
      };
    case RESET_CART:
      return {
        ...state,
        cart: null,
        cartLoading: false,
      }
    case SET_CART_LOADING:
      return {
        ...state,
        cartLoading: data,
      };
    case SET_CART_QTY_COUNT:
      return {
        ...state,
        cartQtyCount: data || 0,
      };
    case SET_PROMO_LIST:
      return {
        ...state,
        promoList: data,
      };
    case UPDATE_SCRATCH_CARD:
      let {scratched, unscratched} = state?.promoList?.scratchCards
      let scratchcardIndex
      let scratchedData = unscratched.filter((item,index) => {
        if(item.id === data?.promotionId){
          scratchcardIndex=index
          return item
        }
      })
      const updateUnscratchData = unscratched.splice(scratchcardIndex,1)
      scratched.push(...scratchedData)
      return {
        ...state,
        promoList:{...state.promoList,scratchCards:{scratched,unscratched}}
      };
    case SET_CART_SINGLE_SKU:
      return {
        ...state,
        cartSkus: { ...state.cartSkus, ...data }
      };
    case SET_CART_SKUS:
      return {
        ...state,
        cartSkus: data,
      };
    case RESET_CART_SKUS:
      return { ...state, cartSkus: {} };
    default:
      return state;
  }
};

export default cartReducer;
