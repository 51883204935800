import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_LOADING,
  SET_MARK_AS_READ_NOTIFICATIONS,
  SET_READ_NOTIFICATION
} from './notifications.action';

const initialState = {
  notifications: null,
  notificationLoading: false,
};

const notificationsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        referralLoading: data,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notificationLoading: false,
        notifications: data,
      };
    case SET_MARK_AS_READ_NOTIFICATIONS:
      let {notifications} = state
      notifications.notificationsCount = data
      return {
        ...state,
        notifications,
        notificationLoading: false,
      };
    case SET_READ_NOTIFICATION:
      let readNotification = state.notifications;
      let read = [];
      if (readNotification?.general) {
        read = readNotification?.general.map(n => {
          const obj = Object.assign({}, n);
          obj['isNotified'] = true;
          return obj;
        });
        readNotification['general'] = read;
      }
      return {
        ...state,
        notifications:Object.assign({}, readNotification),
        notificationLoading: false,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
