const prefix = 'ADDRESS/';
export const GET_ADDRESS_LISTING = `${prefix}GET_ADDRESS_LISTING`;
export const SET_ADDRESS_LISTING = `${prefix}SET_ADDRESS_LISTING`;
export const RESET_ADDRESS_LISTING = `${prefix}RESET_ADDRESS_LISTING`;
export const SET_DEFAULT_ADDRESS = `${prefix}SET_DEFAULT_ADDRESS`;
export const DELETE_ADDRESS = `${prefix}DELETE_ADDRESS`;
export const SET_LOADING_ADDRESS_LISTING = `${prefix}SET_LOADING_ADDRESS_LISTING`;

export const getCustomerCompleteAddressList = data => {
  return {
    type: GET_ADDRESS_LISTING,
    data,
  };
};

export const setDefaultAddress = data => ({
  type: SET_DEFAULT_ADDRESS,
  data,
});

export const deleteAddress = data => ({
  type: DELETE_ADDRESS,
  data,
});

export const resetAddressList = () => ({ type: RESET_ADDRESS_LISTING });

export const addressListSelector = ({ addressReducer: { addresses } }) =>
  addresses;
