const prefix = 'HOME/';
export const GET_HOME_PAGE_SECTION = `${prefix}GET_HOME_PAGE_SECTION`;
export const SET_HOME_PAGE_SECTION = `${prefix}SET_HOME_PAGE_SECTION`;
export const GET_SPECIAL_PAGE_SECTION = `${prefix}GET_SPECIAL_PAGE_SECTION`;
export const SET_SPECIAL_PAGE_SECTION = `${prefix}SET_SPECIAL_PAGE_SECTION`;
export const GET_ADDRESSES_FOR_LOCATION = `${prefix}GET_ADDRESSES_FOR_LOCATION`;
export const SET_ADDRESSES_FOR_LOCATION = `${prefix}SET_ADDRESSES_FOR_LOCATION`;
export const POST_USER_LOCATION = `${prefix}SET_LOCATION`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const RESET_HOME_PAGE_SECTION = `${prefix}RESET_HOME_PAGE_SECTION`;
export const GET_OFFERS = `${prefix}GET_OFFERS`;
export const SET_OFFERS = `${prefix}SET_OFFERS`;
export const GET_SECTION_DATA = `${prefix}GET_SECTION_DATA`;
export const GET_OPTIMIZED_HOME_PAGE_SECTION = `${prefix}GET_OPTIMIZED_HOME_PAGE_SECTION`;
export const GET_HOMEPAGE_AND_BANNER_DETAIL = `${prefix}GET_HOMEPAGE_AND_BANNER_DETAIL`;

export const getHomePageSection = () => ({ type: GET_HOME_PAGE_SECTION });

export const getOptimizedHomePageSection = () => ({ type: GET_OPTIMIZED_HOME_PAGE_SECTION });

export const getSpecialPageSection = data => ({ type: GET_SPECIAL_PAGE_SECTION, data });

export const resetHomePage = () => ({ type: RESET_HOME_PAGE_SECTION });

export const postUserLocation = (data, cb) => ({ type: POST_USER_LOCATION, data, cb });

export const getAddressForLocation = () => ({ type: GET_ADDRESSES_FOR_LOCATION });

export const getOffers = () => ({ type: GET_OFFERS });

export const getSectionData = data => ({ type: GET_SECTION_DATA, data });

export const getHomePageAndBannerDetail = () => ({
    type: GET_HOMEPAGE_AND_BANNER_DETAIL
})