import { all, call, put, select } from 'redux-saga/effects';
import {
  RESET_PRODUCTS,
  RESET_SUBSCRIPTION,
  subscriptionItemsSelector,
  SET_CALENDAR,
  SET_LOADING,
  SET_PRODUCTS,
  SET_SAVED_PAYMENTS,
  SET_PAYMENT_DETAILS,
  SET_SUMMARY,
  SET_SUBSCRIPTION_CONFIRMATION,
  PUT_SELECTED_DAY,
  PUT_PRODUCTS_AND_PRICE,
  SET_SLOT,
  SET_WEEKS,
} from './subscription.action';
import {
  addSubscriptionItemApi,
  getSubscriptionCalendarApi,
  getSubscriptionItemsApi,
  postDeliverySlotApi,
  postSubscriptionCalendarIdApi,
  removeSubscriptionItemApi,
  getPaymentMethodsApi,
  getPaymentSummaryApi,
  postPlaceSubscriptionOrderApi,
  postSaveSubscriptionUserPaymentApi,
  postVerifySubscriptionPaymentApi,
  postVerifySubscriptionPaymentApiPayU,
  getSubscriptionSummaryApi,
  getSubscriptionConfirmationApi,
} from './subscription.api';
import { launchInfoSelector, SET_NOTIFICATION_MSG } from '../../initial.action';
import {
  SUBSCRIPTION_FAILURE_REDIRECT,
  SUBSCRIPTION_STEPS,
  PAYMENT_GATEWAYS,
  ATC_VIBRATE_PATTERN,
} from '../../util/constantServices';
import { getPGCustomerIdSaga } from '../checkout/checkout.saga';
import config from '../../../appConfig';
import RazorpayService from '../../util/RazorpayService';
import PayuService from '../../util/PayuService';
import { SET_NON_SERVICEABLE_MODAL } from '../modal/modal.action';
import {sendAnalytics} from 'hb-redux-store/analytics';

const vibrate = pattern => {
  if (window.navigator?.vibrate) {
    window.navigator.vibrate(pattern);
  }
};

export const weeksSelector = ({ subscriptionReducer: { calendar : { weeks } } }) =>
weeks;


export function* getSavedPaymentsSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: savedPayments } = yield call(getPaymentMethodsApi);
    yield put({ type: SET_SAVED_PAYMENTS, data: savedPayments });
  } catch (e) {
    console.log('Err@ getSavedPaymentsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.message ||
      e?.data?.description ||
      'Error loading subscription details';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
  }
}

export function* getSubscriptionCalendarSaga() {
  try {
    yield put({ type: RESET_SUBSCRIPTION });
    yield put({ type: SET_LOADING, data: true });
    const { data: calendarResp } = yield call(getSubscriptionCalendarApi);
    const calendarData = {
      ...calendarResp,
      selectedSlot: calendarResp.timeSlots?.find(s => s.isSelected),
    };
    const { subscriptionId } = calendarResp;
    const activeWeek = calendarResp.weeks?.find(w => w.isWeekActive);
    if (activeWeek) {
      const week = activeWeek;
      week.selected = true;
      calendarData.activeWeek = week;
      const activeDay = week.days?.find(d => d.isDayActive);
      if (activeDay) {
        activeDay.selected = true;
        const day = { ...activeDay, subscriptionId };
        calendarData.activeDay = day;
        calendarData.activeSCId = day.subscriptionCalendarId || null;
      }
    }
    if (calendarData.activeDay) {
      const { data: itemResp } = yield call(getSubscriptionItemsApi, {
        subscriptionId,
        subscriptionCalendarId: calendarData.activeSCId,
      });
      yield put({
        type: SET_PRODUCTS,
        data: {
          selectedDay: calendarData.activeDay,
          sections: itemResp.sectionWiseProducts.map(section => ({
            ...section,
            changeCounter: 0,
          }))
        }
      });
    }
    yield put({ type: SET_CALENDAR, data: calendarData });
  } catch (e) {
    console.log('Err@ getSubscriptionDetailsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.message ||
      e?.data?.description ||
      'Error loading subscription details';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
  }
}

export function* postSlotSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: slotResp } = yield call(postDeliverySlotApi, data.slot);
    yield put({ type: SET_SLOT, data: data.slot });
    data.successCb(data.slot);
    yield put({ type: SET_LOADING, data: false });
  } catch (e) {
    console.log('Err@ postSlotSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.message ||
      e?.data?.description ||
      e.data?.error?.errorMessage ||
      'Error selecting subscription slot';
    if (message === 'Please select a location.') {
      yield put({
        type: SET_NON_SERVICEABLE_MODAL,
        data: { visible: true, source: 'subscription' }
      });
    } else {
      yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
    }
  }
}

export function* getSubscriptionSummarySaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: summaryResp } = yield call(getSubscriptionSummaryApi, data);
    const weeks = summaryResp.weeks?.map(week => {
      let weekItemCount = 0;
      const days = week?.days?.map(day => {
        let dayItemCount = 0;
        const a = day?.subscriptionItems?.map(
          ({ inCartQty }) => (dayItemCount += inCartQty)
        );
        weekItemCount += dayItemCount;
        // if (dayItemCount) console.log('Day: ', day.formatedDate, dayItemCount);
        return { ...day, dayItemCount };
      });
      // console.log('Week: ', week.title, weekItemCount);
      return { ...week, weekItemCount, days };
    });
    yield put({ type: SET_SUMMARY, data: { ...summaryResp, weeks } });
  } catch (e) {
    console.log('Err @getSubscriptionSummarySaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.message ||
      e?.data?.description ||
      'Error loading subscription summary';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
  }
}

export function* getSubscriptionItemsSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    yield put({ type: RESET_PRODUCTS });
    const { data: itemResp } = yield call(getSubscriptionItemsApi, data);
    const { successCb = () => {}, failCb, ...restParams } = data;
    yield put({
      type: SET_PRODUCTS,
      data: {
        selectedDay: restParams,
        sections: itemResp.sectionWiseProducts.map(section => ({
          ...section,
          products: section.products.sort(
            (a, b) => b.skus[0].inCartQuantity - a.skus[0].inCartQuantity
          ),
          changeCounter: 0,
        }))
      }
    });
    successCb(data);
  } catch (e) {
    console.log('Err@ getSubscriptionItemsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.message || e?.data?.description || 'Error getting products.';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
    data.failCb(e);
  }
}

// function* setWeeksAndDaysAfterItemUpdate(data, added = true) {
//   console.warn('setWeeksAndDaysAfterItemUpdate', data);
//   try {
//     const weeks = yield select(weeksSelector);
//     // console.warn('Weeks', JSON.stringify(weeks));
//     weeks?.forEach((week) => {
//       week?.days.forEach((day) => {
//         week.selected = false;
//         if(day?.date === data.date) {
//
//           console.warn('targetWeek', week.title, day?.subscriptionItems?.length);
//           week.hasItems = false;
//           day.hasItems = false;
//           day?.subscriptionItems?.forEach((product) => {
//             week.hasItems = product?.inCartQty > 0;
//             week.selected = true;
//             day.hasItems = product?.inCartQty > 0;
//           })
//           if(added) {
//             week.hasItems = true;
//             day.hasItems = true;
//           }
//         }
//       });
//     });
//     console.warn('computed week =>', JSON.stringify(weeks));
//
//
//     yield put({ type: SET_WEEKS, data: { weeks } });
//   } catch(e) {
//     console.warn('Error @setWeeksAndDaysAfterItemUpdate', e);
//   }
// }

export function* postAddSubscriptionItemSaga({ data }) {
  try {
    // yield put({ type: SET_LOADING, data: true });
    let { subscriptionCalendarId, selectedDate, subscriptionId } = data;
    if (!subscriptionCalendarId) {
      const { data: scResp } = yield call(postSubscriptionCalendarIdApi, {
        selectedDate,
        subscriptionId
      });
      subscriptionCalendarId = scResp.subscriptionCalendarId;
      data.subscriptionCalendarId = subscriptionCalendarId;
      yield put({ type: PUT_SELECTED_DAY, data: { subscriptionCalendarId } });
    }
    const { data: resp } = yield call(addSubscriptionItemApi, {
      ...data,
      subscriptionCalendarId
    });
    yield updateItemCount(data, resp, true);
    if (config.isApp) {
      data.successOpCb({
        sku: data.sku,
        skuCount: resp.itemQuantity,
        message: 'Item added to subscription.'
      });
    }
    // yield call(setWeeksAndDaysAfterItemUpdate, data, true);
    vibrate(ATC_VIBRATE_PATTERN);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: { show: true, message: 'Item added to subscription.' },
    });

  } catch (e) {
    console.log('Err@ postAddSubscriptionItemSaga: ', e);
    // yield put({ type: SET_LOADING, data: false });
    const errorMessage =
      e?.message ||
      e?.data?.description ||
      e.data?.error?.errorMessage ||
      'Error adding subscription item';
    if (errorMessage === 'Please select a location.') {
      yield put({
        type: SET_NON_SERVICEABLE_MODAL,
        data: { visible: true, source: 'subscription' }
      });
    }
    data.failOpCb({ errorMessage });
  }
}

export function* postRemoveSubscriptionItemSaga({ data }) {
  try {
    // yield put({ type: SET_LOADING, data: true });
    const { data: resp } = yield call(removeSubscriptionItemApi, data);
    yield updateItemCount(data, resp, false);
    vibrate(ATC_VIBRATE_PATTERN);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: { show: true, message: 'Item removed from subscription.' }
    });
    if(config.isApp) {
      data.successOpCb({
        sku: data.sku,
        skuCount: resp.itemQuantity,
        message: 'Item removed from subscription.'
      });
    }
  } catch (e) {
    console.log('Err@ postRemoveSubscriptionItemSaga: ', e);
    // yield put({ type: SET_LOADING, data: false });
    const errorMessage =
      e?.message ||
      e?.data?.description ||
      e.data?.error?.errorMessage ||
      'Error removing subscription item';
    data.failOpCb({ errorMessage });
  }
}

function* updateItemCount(skuData, countData, isAddOp) {
  const { subscriptionItems, selectedDay, calendar } = yield select(
    subscriptionItemsSelector
  );
  let { strikedPrice = 0, finalPrice = 0, weeks } = calendar || {};
  const { onSale = false, retailPrice = 0, salePrice = 0 } = countData;
  if (isAddOp) {
    if (onSale) {
      strikedPrice += retailPrice;
      finalPrice += salePrice;
    } else {
      strikedPrice += retailPrice;
      finalPrice += retailPrice;
    }
  } else {
    if (onSale) {
      strikedPrice -= retailPrice;
      finalPrice -= salePrice;
    } else {
      strikedPrice -= retailPrice;
      finalPrice -= retailPrice;
    }
  }
  const newWeeks = weeks?.map(w => {
    const week = { ...w };
    const days = w.days?.map(d => {
      const day = { ...d };
      if (d.date === selectedDay.date) {
        day.subscriptionCalendarId = skuData.subscriptionCalendarId;
      }
      return day;
    });
    week.days = days;
    return week;
  });
  const sections = [];
  subscriptionItems.map(section => {
    const newSection = { ...section };
    newSection.products = section.products.map(p => {
      if (p.productId !== skuData.productId) return { ...p };
      newSection.changeCounter = section.changeCounter + 1;
      return {
        ...p,
        skus: [{ ...p.skus[0], inCartQuantity: countData.itemQuantity }]
      };
    });
    sections.push(newSection);
  });
  const data = {
    selectedDay: { ...selectedDay },
    sections,
    strikedPrice,
    finalPrice,
    weeks: newWeeks,
  };
  yield put({ type: PUT_PRODUCTS_AND_PRICE, data });
}

export function* getSubscriptionPaymentDetailsSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const promises = [call(getPaymentSummaryApi), call(getPaymentMethodsApi)];
    const [summaryResp, paymentMethodsResp] = yield all(promises);
    const paymentSummary = summaryResp.data;
    const paymentMethods = paymentMethodsResp.data;
    yield put({
      type: SET_PAYMENT_DETAILS,
      data: { paymentSummary, paymentMethods },
    });
    const pgCid = paymentMethods.pgCid || null;
    const { pgId } = paymentMethods;
    let { savedUPIs } = paymentMethods;
    if (pgId === PAYMENT_GATEWAYS.PAYU.id) {
      savedUPIs = savedUPIs.map(upi => ({ ...upi, tokenId: Math.random() }));
      paymentMethods.savedUPIs = savedUPIs;
    }

    yield put({
      type: SET_PAYMENT_DETAILS,
      data: { paymentSummary, paymentMethods, pgCid }
    });
    if (!pgCid && pgId === PAYMENT_GATEWAYS.RAZORPAY.id) {
      const pgCidResp = yield call(getPGCustomerIdSaga);
      console.log('GoT PGCID:', pgCidResp);
    }
    yield put({ type: SET_LOADING, data: false });
  } catch (e) {
    console.log('Err@ getSubscriptionPaymentDetailsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
  }
}

function* placeSubscriptionOrderSaga() {
  try {
    // const quantityFailure = {"action":"failure","data":{"status":"error","redirect":"cart","description":"Session item Quantity mismatch for cart item : EGG-jwlw"}};
    const data = yield call(postPlaceSubscriptionOrderApi);
    return data;
  } catch (e) {
    console.log('Err @placeSubscriptionOrderSaga: ', e);
    return { data: { step: SUBSCRIPTION_STEPS.PLACE_ORDER, error: e } };
  }
}

export function* postPlaceOrderSaga({
  data: cardData,
  razorPayInstance = null,
  payUInstance = null,
  cb,
  appFailedCb = null,
  payUSuccessCb = null,
  payUFailureCb = null
}) {
  try {
    const nonPOLMode = ['pod', 'cod', 'hbw'];
    if (
      !config.isApp &&
      cardData.pgId === PAYMENT_GATEWAYS.RAZORPAY.id &&
      !RazorpayService.getRazorPayInstance()
    ) {
      RazorpayService.initRazorpay();
    }
    const postDetails = {
      amount: cardData.amount,
      paymentMode: cardData.payVia,
      pgId: cardData.pgId,
      paymentMethod: 'POL',
    };
    if (cardData?.payVia === 'CC') {
      postDetails.cardType = cardData?.cardType; // VISA/MC/RUPAY
      postDetails.last4 = cardData?.cardType;
      postDetails.saveVpa = !!cardData?.save;
    }
    if (cardData?.payVia === 'upi') {
      postDetails.vpa = cardData?.vpa;
      postDetails.saveVpa = !!cardData?.save;
    }
    if (cardData?.payVia === 'netbanking') {
      postDetails.bankCode = cardData?.bank;
    }
    if (cardData?.wallet) {
      postDetails.paymentWallet = cardData?.wallet;
    }
    if (nonPOLMode.indexOf(cardData?.mode) > -1) {
      postDetails.paymentMode = cardData?.mode;
      postDetails.paymentMethod = cardData?.mode;
    }
    const launchInfo = yield select(launchInfoSelector);
    const { data: resp } = yield call(placeSubscriptionOrderSaga);
    console.log('place order saga: ', JSON.stringify(resp));
    if (resp.step) {
      if (cardData.failCb && !config.isApp) {
        return cardData.failCb(resp);
      } else if (appFailedCb) {
        return appFailedCb(resp);
      }
    }
    // debugger
    // console.warn('postPlaceOrderApi', resp, cardData);
    const paymentData = {
      subscriptionNo: resp.subscriptionNo,
      paymentMode: cardData.mode,
      data: postDetails,
    };
    // console.warn('paymentData', paymentData);
    const { data: paymentResp } = yield call(
      postSaveSubscriptionUserPaymentApi,
      paymentData
    );
    console.log('save user payment: ', JSON.stringify(paymentResp));
    if (nonPOLMode.indexOf(cardData?.mode) > -1) {
      if (!config.isApp) {
        cardData.successCb &&
          cardData.successCb({ subscriptionNo: paymentData.subscriptionNo });
      } else {
        cb({
          action: resp.status,
          data: {
            subscriptionNo: resp.subscriptionNo
          }
        });
      }
    } else {
      if (!config.isApp) {
        if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
          const payuData = {
            ...postDetails,
            ...cardData,
            firstname: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            txnid: resp.subscriptionNo,
            hash: paymentResp.payUHash.paymentHash,
            productinfo: paymentResp.payUHash.productInfo,
            surl: paymentResp.payUHash.successURL,
            curl: paymentResp.payUHash.cancelURL,
            furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            key: process.env.REACT_APP_PAYU_KEY || 'LlaSG8',
            payuUrl: process.env.REACT_APP_PAYU_POST_URL,
          };
          payuData.user_credentials = `${payuData.key}:${cardData.pgCid}`;
          PayuService.makePayment(payuData);
          return;
        }
        const rzrp = RazorpayService.getRazorPayInstance();
        if (!rzrp) {
          return cardData.failCb({
            step: SUBSCRIPTION_STEPS.PG_NOT_LOADED,
            redirect: SUBSCRIPTION_FAILURE_REDIRECT.PG_NOT_LOADED,
          });
        }
        const rzrData = {
          customer_id: cardData.pgCid,
          order_id: paymentResp.pgOrderId,
          ...cardData,
          contact: launchInfo.mobile,
        };
        rzrData.amount = postDetails.amount * 100;
        delete rzrData.mode;
        delete rzrData.payVia;
        delete rzrData.pgCid;
        delete rzrData.pgId;
        delete rzrData.surl;
        delete rzrData.last4;
        delete rzrData.bin;
        delete rzrData.cardType;
        const razorPaySuccessCb = resp => {
          console.log('PaymentSuccess: ', resp);
          const verifyData = {
            status: 'success',
            success: {
              pgOId: resp.razorpay_order_id,
              pgPId: resp.razorpay_payment_id,
              pgSId: resp.razorpay_signature,
            },
          };
          postVerifySubscriptionPaymentApi({
            subscriptionNo: paymentData.subscriptionNo,
            data: verifyData,
          })
            .then(verifiedResp => {
              console.log('Payment verified', verifiedResp);
              if (!config.isApp) {
                cardData.successCb &&
                  cardData.successCb({
                    subscriptionNo: paymentData.subscriptionNo,
                  });
              }
            })
            .catch(e => {
              console.log('Verification error: ', e);
              cardData.failCb &&
                cardData.failCb({
                  step: SUBSCRIPTION_STEPS.PAYMENT_VERIFICATION,
                  error: {
                    redirect: SUBSCRIPTION_FAILURE_REDIRECT.ORDER_FAILURE,
                  }
                });
            });
        };
        const razorPayErrorCb = err => {
          console.log('RazorPay error: ', err);
          postVerifySubscriptionPaymentApi({
            subscriptionNo: paymentData.subscriptionNo,
            data: {
              status: 'failure',
              ...err,
            }
          })
            .then(failedResp => {
              console.log('Failed payment reported. ', failedResp);
              if (!config.isApp) {
                cardData.failCb(failedResp.data?.description);
              }
            })
            .catch(e => {
              console.log('Failure reporting error: ', e);
            });
        };
        const { deviceDetector = null } = config;
        console.log('deviceDetector: ', deviceDetector?.deviceDetect());
        console.log(
          'isIos device: ',
          deviceDetector?.isMacOs,
          deviceDetector?.isMobileSafari,
          deviceDetector?.isSafari,
          deviceDetector?.isIOS
        );
        if (
          deviceDetector?.isMacOs ||
          deviceDetector?.isMobileSafari ||
          deviceDetector?.isSafari ||
          deviceDetector?.isIOS
        ) {
          console.log('Qualifying as apple device.');
          cardData.failCb({
            step: SUBSCRIPTION_STEPS.PAYMENT_VERIFICATION,
            redirect: SUBSCRIPTION_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
            pgDetails: {
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            }
          });
        } else {
          const payNow = document.createElement('BUTTON');
          payNow.onclick = function() {
            console.log('Qualifying as non-apple device.');
            const razorPayPopup = RazorpayService.makePayment({
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            });
            setTimeout(() => {
              const hasPopup = !!razorPayPopup?._payment?.popup?.window
                ?.outerHeight;
              // debugger
              if (!hasPopup) {
                console.log('non-apple device popup failed');
                cardData.failCb({
                  step: SUBSCRIPTION_STEPS.PAYMENT_VERIFICATION,
                  redirect: SUBSCRIPTION_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
                  pgDetails: {
                    razorPayData: rzrData,
                    razorPaySuccessCb,
                    razorPayErrorCb,
                  }
                });
              }
            }, 250);
          };
          payNow.click();
        }
        // console.log('Razr data: ', rzrData);
        // rzrp.on('payment.success', razorpaySuccessCb);
        // rzrp.on('payment.error', razorpayErrorCb);
        // const razorPayPopup = rzrp.createPayment(rzrData);
        // setTimeout(() => {
        //   // TODO: Update logic to recognise if modal is opened or not.
        //   const hasPopup = !!razorPayPopup._payment?.popup?.window?.outerHeight;
        //   if (!hasPopup) {
        //     cardData.failCb({
        //       step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
        //       redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
        //       pgDetails: {
        //         rzrData,
        //         razorpaySuccessCb,
        //         razorpayErrorCb,
        //       }
        //     });
        //   }
        // }, 200);
      } else {
        if (cardData?.paymentMethod === 'POL') {
          delete cardData?.paymentMethod;
        }
        console.warn(
          'isPayu',
          cardData.pgId === PAYMENT_GATEWAYS.PAYU.id,
          payUInstance
        );
        if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
          const payuData = {
            ...postDetails,
            ...cardData,
            firstName: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            transactionId: resp.subscriptionNo,
            hash: paymentResp.payUHash.paymentHash,
            productInfo: paymentResp.payUHash.productInfo,
            android_surl: paymentResp.payUHash.successURL,
            android_furl: paymentResp.payUHash.failureURL,
            ios_surl: paymentResp.payUHash.successURL,
            ios_furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            key: config.ENV.PG_PU_KEY,
            payment: paymentResp.payUHash.paymentHash,
            vas_for_mobile_sdk: paymentResp.payUHash.vasForMobileSDK,
            payment_related_details_for_mobile_sdk:
              paymentResp.payUHash.paymentRelatedDetailsForMobileSDK,
            payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
            payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT
          };
          payuData.userCredentials = `${payuData.key}:${cardData.pgCid}`;
          console.warn('paymentResp', paymentResp);
          console.warn('paymentObject', payuData);
          var paymentObject = {
            payUPaymentParams: payuData,
            payUCheckoutProConfig: {}
          };

          let finalPayUPayload = paymentObject;
          if (config?.getDeviceType() === 'iphone') {
            finalPayUPayload = payuData;
          }

          // Analytics
          const key = 'pg_payu_initialize'
          const payload = {
            ...postDetails,
            firstName: paymentResp.payUHash.hashFirstName,
            email: paymentResp.payUHash.hashEmail,
            phone: launchInfo.mobile,
            transactionId: resp.orderNo,
            productInfo: paymentResp.payUHash.productInfo,
            android_surl: paymentResp.payUHash.successURL,
            android_furl: paymentResp.payUHash.failureURL,
            ios_surl: paymentResp.payUHash.successURL,
            ios_furl: paymentResp.payUHash.failureURL,
            amount: paymentResp.payUHash.amount,
            udf1: paymentResp.payUHash.udf1,
            udf2: paymentResp.payUHash.udf2,
            udf3: paymentResp.payUHash.udf3,
            udf4: paymentResp.payUHash.udf4,
            udf5: paymentResp.payUHash.udf5,
            payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
            payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT,
            deviceId: config.getDeviceId(),
          };
          sendAnalytics({
            key,
            payload,
            firebasePriority: 1,
            clevertapPriority: 1,
          }) 
 

          payUInstance.openCheckoutScreen(
            finalPayUPayload,
            (payUresponse, merchantResponse) => {

              // Analytics
              const key = 'pg_payu_error'
              const payload = {
                  payUResponse: payUresponse,
                  merchantResponse: merchantResponse || "",
                  deviceId: config.getDeviceId()
              };
              sendAnalytics({
                key,
                payload,
                firebasePriority: 1,
                clevertapPriority: 1,
              })
              
 
              // error callback
              console.warn('PayU Error', payUresponse, merchantResponse);
              const verifyData = {
                status: 'failure',
                ...payUresponse,
                ...(merchantResponse || {})
              };
              postVerifySubscriptionPaymentApiPayU({
                subscriptionNo: resp.subscriptionNo,
                data: verifyData,
                sdkCallBackStatus: 'failure'
              })
                .then((validateResponse) => {
                  // payU error with validation error success
                  // console.warn('payU error with validation API success');
                  // payUFailureCb(payUresponse, merchantResponse);

                  // Analytics 
                  const key = 'pg_payu_error_validate_success'
                  const payload = {
                    response: JSON.stringify(validateResponse),
                    orderNo: resp.orderNo,
                    status: 'failure',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'failure',
                    deviceId: config.getDeviceId()
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                    clevertapPriority: 1,
                  })
                 

                  payUSuccessCb(payUresponse, merchantResponse, resp.subscriptionNo, validateResponse);
                })
                .catch((err) => {
                  // payU error with validation failed
                  // console.warn('payU error with validation failed');

                  // Analytics
                  const key = 'pg_payu_error_validate_error'
                  const payload = {
                    orderNo: resp.orderNo,
                    status: 'failure',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'failure',
                    description: err?.data?.description,
                    orderStatus: err?.data?.orderStatus,
                    deviceId: config.getDeviceId()
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                    clevertapPriority: 1,
                  })
                
                   
                  payUFailureCb(payUresponse, merchantResponse, resp.subscriptionNo);
                });
            },
            (payUresponse, merchantResponse) => {
              //success callback
              const verifyData = {
                status: 'success',
                ...merchantResponse,
              };

              // Analytics
              const key = 'pg_payu_success'
              const payload = {
                req: finalPayUPayload,
                res: {
                  ...payUresponse,
                  ...merchantResponse
                },
                deviceId: config.getDeviceId()
              };
              sendAnalytics({
                key,
                payload,
                firebasePriority: 1,
                clevertapPriority: 1,
              })
              
               
              postVerifySubscriptionPaymentApiPayU({
                subscriptionNo: resp.subscriptionNo,
                data: verifyData,
                sdkCallBackStatus: 'success'
              })
                .then((validateResponse) => {
                  // payU success with validation success
                  console.warn('payU success with validation success');

                  // Analytics
                  const key = 'pg_payu_success_validate_success'
                  const payload = {
                    response: JSON.stringify(validateResponse),
                    orderNo: resp?.orderNo,
                    status: 'success',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'success',
                    deviceId: config.getDeviceId()
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                    clevertapPriority: 1,
                  })

                  
                  payUSuccessCb(payUresponse, merchantResponse, resp.subscriptionNo, validateResponse);
                })
                .catch(err => {
                  // payU success with validation failed
                  console.warn('payU success with validation failed', err);

                  // Analytics
                  const key = 'pg_payu_success_validate_error'
                  const payload = {
                    orderNo: resp?.orderNo,
                    status: 'success',
                    payUresponse: payUresponse || '',
                    merchantResponse: merchantResponse || '',
                    sdkCallBackStatus: 'success',
                    description: err?.data?.description,
                    orderStatus: err?.data?.orderStatus,
                    deviceId: config.getDeviceId()
                  };
                  sendAnalytics({
                    key,
                    payload,
                    firebasePriority: 1,
                    clevertapPriority: 1,
                  })

                 
                  payUFailureCb(payUresponse, merchantResponse, resp.subscriptionNo);
                });
            }
          );
          return;
        }
        const rzrData = {
          customer_id: cardData.pgCid,
          order_id: paymentResp.pgOrderId,
          ...cardData,
          contact: launchInfo.mobile,
          currency: 'INR',
        };
        rzrData.amount = postDetails.amount * 100;
        delete rzrData.mode;
        delete rzrData.payVia;
        delete rzrData.pgCid;
        delete rzrData.pgId;
        delete rzrData.surl;

        console.warn('Payment by App', rzrData, config.ENV.PG_KEY);
        razorPayInstance
          .open(rzrData)
          .then(resp => {
            // handle success
            const verifyData = {
              status: 'success',
              success: {
                pgOId: resp?.razorpay_order_id,
                pgPId: resp?.razorpay_payment_id,
                pgSId: resp?.razorpay_signature,
              }
            };
            console.warn('Razorpay verifyData', verifyData);

            postVerifySubscriptionPaymentApi({
              subscriptionNo: paymentData.subscriptionNo,
              data: verifyData,
            })
              // Verify Payment Transaction
              .then(verifiedResp => {
                console.log('Payment verified', verifiedResp);
                if (config.isApp) {
                  // here fail
                  cb(verifiedResp);
                }
              })
              .catch(e => {
                console.log('Verification error: ', e);
                appFailedCb({
                  step: SUBSCRIPTION_STEPS.PAYMENT_VERIFICATION,
                  redirect: SUBSCRIPTION_FAILURE_REDIRECT.ORDER_FAILURE,
                });
              });
          })
          .catch(err => {
            // handle failure
            console.warn('RazorPay error: ', err);
            let errorMsg = '';
            let statusCode = '';
            try {
              errorMsg = JSON.parse(err?.description || {})?.error;
              statusCode = JSON.parse(err?.description || {})?.http_status_code;
            } catch (e) {
              console.warn('Error Parsing Razorpay error', e);
              errorMsg = {
                error: err?.description
              };
              statusCode = '';
            }
            postVerifySubscriptionPaymentApi({
              subscriptionNo: paymentData.subscriptionNo,
              data: {
                status: 'failure',
                error: errorMsg,
                http_status_code: statusCode
              }
            })
              .then(failedResp => {
                // const formattedError = (failedResp?.data?.description ) || (err?.description && JSON.parse(err?.description) && JSON.parse(err?.description)?.error?.description);
                console.warn(
                  'Failed payment reported. ',
                  failedResp,
                  JSON.parse(err?.description).description
                );
                // appFailedCb(formattedError);

                appFailedCb(failedResp);
              })
              .catch(e => {
                console.warn('Failure reporting error: ', e);
                cb(err?.description);
              });
          });
      }
    }
  } catch (e) {
    console.log('Err @postPlaceOrderSaga: ', e);
    cb && cb(e);
    let errorMsg = 'Error while placing order. Please try again.';
    if (e.data?.description) {
      errorMsg = e.data?.description;
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg
      }
    });
  }
}

export function* getSubscriptionConfirmationSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: subscriptionDetails } = yield call(
      getSubscriptionConfirmationApi,
      data
    );
    if (!config.isApp) {
      // ==== Sending FB purchase event
      // ==== https://www.facebook.com/business/help/402791146561655?id=1205376682832142
      const {
        cgstTaxAmount = 0,
        sgstTaxAmount = 0,
        orderPayableAmount = 0,
        deliveryCharges = 0,
      } = subscriptionDetails;
      const trackedOrder = config.LSGetItem('trackedOrder') || {};
      if (!trackedOrder[data.orderNo]) {
        window?.fbq &&
          window.fbq('track', 'Subscribe', {
            value: orderPayableAmount,
            currency: 'INR',
            orderNo: data.orderNo,
            purchaseValue: orderPayableAmount,
            customerId: config.getDeviceId(),
          });
        console.log('tracked fb:');
        const { ga } = config.ReactGA;
        // Sending ecommerce Analytics.js
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
        ga('require', 'ecommerce');
        const transactionProducts =
          subscriptionDetails?.orderDetails?.cartItems?.map(oi => {
            const item = {
              id: oi.sku,
              sku: oi.sku,
              name: oi.productName,
              price: oi.onSale ? oi.salePrice : oi.retailPrice,
              quantity: oi.inCartQty || oi.quantity,
              currency: 'INR',
            };
            ga('ecommerce:addItem', item);
            return item;
          }) || [];
        ga('ecommerce:addTransaction', {
          id: data.orderNo,
          revenue: orderPayableAmount,
          shipping: deliveryCharges,
          currency: 'INR',
        });
        ga('ecommerce:send');
        ga('ecommerce:clear');
        console.log('tracked using GA:');
        // ==============   Standard Ecomm using universal analytics
        // ==============   https://support.google.com/tagmanager/answer/6107169?authuser=5#standard-ecommerce
        // const ecomData = {
        //   transactionId: data.orderNo,
        //   transactionTotal: orderPayableAmount,
        //   transactionShipping: deliveryCharges,
        //   transactionProducts,
        //   event: 'subscribe',
        // };
        // const dataLayer = window.dataLayer = window.dataLayer || [];
        // dataLayer.push(ecomData);

        // ==============    Sending ecommerce with gtag.js
        // ==============    https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
        const gtagData = {
          transaction_id: data.orderNo,
          value: orderPayableAmount,
          currency: 'INR',
          tax: cgstTaxAmount + sgstTaxAmount,
          shipping: deliveryCharges,
          items: transactionProducts,
          event: 'subscribe',
        };
        const noOp = () => {};
        const gtag = window.gtag || noOp;
        gtag('event', 'subscribe', gtagData);
        console.log('tracked using gtag.js:');
        const gtmDataLayer = window?.dataLayer || [];
        gtmDataLayer.push(gtagData);
        trackedOrder[data.orderNo] = new Date();
        config.LSSetItem('trackedOrder', trackedOrder);
      }
    }
    yield put({
      type: SET_SUBSCRIPTION_CONFIRMATION,
      data: subscriptionDetails
    });
  } catch (e) {
    console.log('Err@ getSubscriptionConfirmationSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.message ||
      e?.data?.description ||
      'Error loading subscription confirmation details';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
  }
}
