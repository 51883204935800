const prefix = 'PRODUCT/';
export const GET_PDP_DETAILS = `${prefix}GET_PDP_DETAILS`;
export const SET_PDP_DETAILS = `${prefix}SET_PDP_DETAILS`;
export const GET_COMBO_PDP_DETAILS = `${prefix}GET_COMBO_PDP_DETAILS`;
export const GET_DEALS = `${prefix}GET_DEALS`;
export const SET_DEALS = `${prefix}SET_DEALS`;
export const GET_RANDOM_SECTION = `${prefix}GET_RANDOM_SECTION`;
export const SET_RANDOM_SECTION = `${prefix}SET_RANDOM_SECTION`;
export const GET_SECTION_PLP = `${prefix}GET_SECTION_PLP`;
export const SET_SECTION_PLP = `${prefix}SET_SECTION_PLP`;
export const GET_CATEGORIES = `${prefix}GET_CATEGORIES`;
export const SET_CATEGORIES = `${prefix}SET_CATEGORIES`;
export const GET_CATEGORY_PLP_DETAILS = `${prefix}GET_CATEGORY_PLP_DETAILS`;
export const SET_CATEGORY_PLP_DETAILS = `${prefix}SET_CATEGORY_PLP_DETAILS`;
export const SET_LOADING_PDP = `${prefix}SET_LOADING_PDP`;
export const RESET_PDP = `${prefix}RESET_PDP`;
export const RESET_PLP = `${prefix}RESET_PLP`;
export const GET_SEARCH_LIST = `${prefix}GET_SEARCH_LIST`;
export const SET_SEARCH_LIST = `${prefix}SET_SEARCH_LIST`;
export const RESET_CATEGORIES = `${prefix}RESET_CATEGORIES`;
export const SET_NOTIFY_USER = `${prefix}SET_NOTIFY_USER`;

export const getCategories = () => ({ type: GET_CATEGORIES });

export const getPDPDetails = data => {
  return {
    type: GET_PDP_DETAILS,
    data,
  };
};

export const getComboPDPDetails = data => ({
  type: GET_COMBO_PDP_DETAILS,
  data,
});

export const resetPDP = () => ({ type: RESET_PDP });

export const resetPLP = () => ({ type: RESET_PLP });

export const resetCategories = () => ({ type: RESET_CATEGORIES })

export const resetProductReducer = () => ({ type: RESET_PDP });

export const getCategoryPLPDetails = (data, isProvisional) => ({
  type: GET_CATEGORY_PLP_DETAILS,
  data,
  isProvisional,
});

export const getSectionPLP = data => ({ type: GET_SECTION_PLP, data });

export const getDeals = () => ({ type: GET_DEALS });

export const getRandomSection = data => ({ type: GET_RANDOM_SECTION, data });

export const getSearchList = (data,cb) => ({ type: GET_SEARCH_LIST, data, cb });

export const clearSearchList = () => ({ type: SET_SEARCH_LIST, data: [] });

export const notifyUser = (data, cb) => ({ type: SET_NOTIFY_USER, data, cb });
