import { takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_MEMBERSHIP_DETAIL,
  ADD_MEMBERSHIP_TO_CART,
  Remove_MEMBERSHIP_TO_CART
} from './hbPrime.action';
import {
  getAllMembershipDetailSaga,
  addMembershipTocartSaga,
  removeMembershipFromCartSaga
} from './hbPrime.saga';

export const HalalBoxPrimeSaga = [
  takeEvery(GET_ALL_MEMBERSHIP_DETAIL, getAllMembershipDetailSaga),
  takeEvery(ADD_MEMBERSHIP_TO_CART, addMembershipTocartSaga),
  takeEvery(Remove_MEMBERSHIP_TO_CART, removeMembershipFromCartSaga),
];
