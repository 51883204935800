import { takeEvery } from 'redux-saga/effects';
import {
  GET_WALLET,
  ADD_WALLET_RECHARGE,
  GET_RECHARGE_METHODS,
  POST_RECHARGE_WALLET,
  POST_PAY_AND_ORDER,
} from './wallet.action';
import {
  getWalletSaga,
  addWalletRechargeSaga,
  getRechargeMethods,
  postRechargeWalletSaga,
  postPayAndPlaceOrderSaga,
} from './wallet.saga';

export const WalletSaga = [
  takeEvery(GET_WALLET, getWalletSaga),
  takeEvery(ADD_WALLET_RECHARGE, addWalletRechargeSaga),
  takeEvery(GET_RECHARGE_METHODS, getRechargeMethods),
  takeEvery(POST_RECHARGE_WALLET, postRechargeWalletSaga),
  takeEvery(POST_PAY_AND_ORDER, postPayAndPlaceOrderSaga),
];
