import { takeEvery, take } from 'redux-saga/effects';
import {
  GET_ORDER_DETAILS,
  GET_ORDERS,
  GET_SAVED_PAYMENTS,
  PUT_PROFILE_DETAILS,
  GET_FAQS,
  GET_ORDER_STATUS,
  TRACK_ORDER_STATUS,
  GET_REFERRAL_LIST,
  GET_INVOICE,
  POST_REORDER,
} from './accounts.action';
import {
  getOrderDetailsSaga,
  getOrdersSaga,
  getSavedPaymentsSaga,
  putProfileDetailsSaga,
  getFaqsSaga,
  getOrderStatusSaga,
  trackOrderSaga,
  getReferralListingSaga,
  getInvoiceSaga,
  postReorderSaga,
} from './accounts.saga';

export const accountsSaga = [
  takeEvery(GET_ORDERS, getOrdersSaga),
  takeEvery(GET_ORDER_DETAILS, getOrderDetailsSaga),
  takeEvery(GET_SAVED_PAYMENTS, getSavedPaymentsSaga),
  takeEvery(PUT_PROFILE_DETAILS, putProfileDetailsSaga),
  takeEvery(GET_FAQS, getFaqsSaga),
  takeEvery(GET_ORDER_STATUS, getOrderStatusSaga),
  takeEvery(TRACK_ORDER_STATUS, trackOrderSaga),
  takeEvery(GET_REFERRAL_LIST, getReferralListingSaga),
  takeEvery(GET_INVOICE, getInvoiceSaga),
  takeEvery(POST_REORDER, postReorderSaga),
];
