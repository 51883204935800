import {
  RESET_LOGIN,
  SET_ERROR_MSG,
  SET_USER,
  SET_VISIBLE_BLOCK_FOR_LOGIN,
  SET_LOADING,
  SET_USER_EMAIL,
  SET_CCA_LOGIN_MSG,
  SET_LOGIN_DISABLED,
  ACTIVE_LOGIN_DISABLED
} from './login.action';
import {
  LOGIN_SCREEN_CTA as CTA,
  LOGIN_SCREEN_STATES as STATES,
} from 'hb-redux-store/util/constantServices';

const initialState = {
  user: null,
  visibleBlock: STATES.PHONE_NO,
  loginCTA: CTA.CONTINUE,
  resendWaitTimer: null,
  errorMsg: null,
  loading: false,
  resetPasswordEmail: null,
  orderForCustomerMessage: null,
  loginDisabled: {},
  isLoginDisabled: false
};

const getLoginCTA = visibleBlock => {
  if (visibleBlock === STATES.PHONE_NO) return CTA.CONTINUE;
  if (visibleBlock === STATES.OTP) return CTA.CONTINUE;
  if (visibleBlock === STATES.EMAIL) return CTA.CONTINUE;
  if (visibleBlock === STATES.FORGOT_PASSWORD) return CTA.SUBMIT;
  if (visibleBlock === STATES.RESET_PASSWORD) return CTA.SUBMIT;
};

const loginReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_VISIBLE_BLOCK_FOR_LOGIN:
      const { visibleBlock } = data;
      return {
        ...state,
        ...data,
        loginCTA: getLoginCTA(visibleBlock),
        errorMsg: null,
      };
    case SET_ERROR_MSG:
      return {
        ...state,
        errorMsg: data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        resetPasswordEmail: data?.accountEmail,
        loading: false,
      };
    case SET_CCA_LOGIN_MSG:
      return {
        ...state,
        orderForCustomerMessage: data,
      };
    case SET_LOGIN_DISABLED:
      return {
        ...state,
        loginDisabled: data
      };
    case ACTIVE_LOGIN_DISABLED:
      return {
        ...state,
        isLoginDisabled: data
      };
    case RESET_LOGIN:
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
