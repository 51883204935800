import request from 'hb-redux-store/util/request';

export const getBlogsWithCategoryApi = () =>
  request.get('/api/blogsWithCategories');

export const getBlogsWithSegmentApi = () =>
  request.get('/api/blogsWithCategories');

export const getBlogBySlugApi = ({ slug }) => request.get(`/api/blogs/${slug}`);

export const getRecentBlogsApi = (count = 5) =>
  request.get(`/api/blogs?count=${count}`);
