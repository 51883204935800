import {
  SET_HOME_PAGE_SECTION,
  SET_LOADING,
  RESET_HOME_PAGE_SECTION,
  SET_ADDRESSES_FOR_LOCATION,
  SET_SPECIAL_PAGE_SECTION,
  SET_OFFERS,
  GET_OFFERS,
} from './home.action';

const initialState = {
  homepageSection: null,
  loading: false,
  savedAddress: null,
  specialPageSection: null,
  offers: null,
  loadingOffers: true,
};

const homeReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_OFFERS:
      return { ...state, loadingOffers: true };
    case SET_HOME_PAGE_SECTION:
      return {
        ...state,
        ...data,
        loading: false
      };
    case RESET_HOME_PAGE_SECTION:
      return {
        ...initialState
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data
      };
    case SET_ADDRESSES_FOR_LOCATION:
      return {
        ...data,
        savedAddress: data,
      };
    case SET_SPECIAL_PAGE_SECTION:
      return {
        ...state,
        loading: false,
        specialPageSection: data,
      };
    case SET_OFFERS:
      return {
        ...state,
        loadingOffers: false,
        offers: data,
      };
    default:
      return state;
  }
};

export default homeReducer;
