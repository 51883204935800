import {
  SET_BLOGS_WITH_CATEGORY,
  SET_LOADING,
  SET_BLOG_BY_SLUG,
  SET_BLOGS_BY_SEGMENT,
  RESET_BLOG_DETAILS,
} from './blog.action';

const initialState = {
  allBlogs: null,
  loading: false,
  blog: null,
  segment: null,
};

const blogReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_BLOGS_WITH_CATEGORY:
      return {
        ...state,
        ...data,
        loading: false
      };
    case SET_BLOGS_BY_SEGMENT:
      return {
        ...state,
        segment: data,
        loading: false
      };
    case SET_BLOG_BY_SLUG:
      return {
        ...state,
        blog: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data
      };
    case RESET_BLOG_DETAILS:
      return {
        ...state,
        blog: null,
      }
    default:
      return state;
  }
};

export default blogReducer;
