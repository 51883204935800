import config from 'appConfig';
import {useHBStore} from 'hb-redux-store/root.hooks.js';
import {selectRemoteConfig} from 'hb-redux-store/feature/remoteConfig/remoteConfig.selectors.js';

export const getDataFromKey = (key, type) => {
  const {firebaseRemoteConfig = {}} = config;
  let data;
  switch (type) {
    case 'json':
      const configValue = firebaseRemoteConfig().getValue(key)?._value;
      data = JSON.parse(configValue);
      break;
    case 'boolean':
      data = firebaseRemoteConfig()
        .getValue(key)
        .asBoolean();
      break;
    case 'number':
      data = firebaseRemoteConfig()
        .getValue(key)
        .asNumber();
      break;
    default:
      data = firebaseRemoteConfig()
        .getValue(key)
        .asString();
  }
  return data;
};

const useRemoteConfig = () => {
  const {state} = useHBStore();
  const {isRemoteConfigFetched} = selectRemoteConfig(state);

  const getRemoteConfigValue = ({key = '', type = 'string'}) => {
    if (isRemoteConfigFetched) {
      return getDataFromKey(key, type);
    }
  };

  return {
    getRemoteConfigValue,
  };
};

export default useRemoteConfig;
