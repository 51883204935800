import request from 'hb-redux-store/util/request';

export const getAddressListing = () =>
  request.get(`/api/getCustomerCompleteAddressList`);

export const setDefaultAddressApi = data =>
  request.post(`/api/markAddressDefault`, data);

export const deleteAddressApi = data =>
  request.post(`/api/deleteCustomerAddress`, data);
