import { call, put, select } from 'redux-saga/effects';
import {
  SET_LOADING_ADDRESS_LISTING,
  SET_ADDRESS_LISTING,
  addressListSelector,
} from './address.action';
import {
  deleteAddressApi,
  getAddressListing,
  setDefaultAddressApi,
} from './address.api';
import {SET_NOTIFICATION_MSG} from "../../initial.action";

export function* getAddressListingSaga() {
  try {
    yield put({ type: SET_LOADING_ADDRESS_LISTING, data: true });
    const { data: resp } = yield call(getAddressListing);
    yield put({ type: SET_ADDRESS_LISTING, data: resp });
  } catch (e) {
    console.warn('Err@ getAddressListingSaga: ', e);
    yield put({ type: SET_LOADING_ADDRESS_LISTING, data: false });
  }
}

export function* setDefaultAddressSaga({ data }) {
  let addresses = null;
  let oldAddress = null;
  try {
    // yield put({ type: SET_LOADING_ADDRESS_LISTING, data: true });
    addresses = yield select(addressListSelector);
    oldAddress = addresses.find(a => a.default);
    if (data.addressId !== oldAddress?.addressId) {
      const newAddresses = addresses.map(address => ({
        ...address,
        default: address.addressId === data.addressId
      }));
      yield put({ type: SET_ADDRESS_LISTING, data: newAddresses });
      yield call(setDefaultAddressApi, data);
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: {
          show: true,
          message: 'Default address changed'
        }
      });
    }
  } catch (e) {
    const addresses = yield select(addressListSelector);
    yield put({ type: SET_ADDRESS_LISTING, data: addresses });
    console.log('Err@ setDefaultAddressSaga: ', e);
    const resetAddresss = addresses.map(address => {
      if (address.addressId !== oldAddress?.addressId) return { ...address };
      return { ...address, default: oldAddress?.default };
    });
    yield put({ type: SET_ADDRESS_LISTING, data: resetAddresss });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Error changing default address. Reload and try again.'
      }
    });
  }
}

export function* deleteAddressSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_ADDRESS_LISTING, data: true });
    yield call(deleteAddressApi, data);
    const addresses = yield select(addressListSelector);
    const newAddresses = addresses.filter(
      address => address.addressId !== data.addressId
    );
    yield put({ type: SET_ADDRESS_LISTING, data: newAddresses });
  } catch (e) {
    console.log('Err@ deleteAddressSaga: ', e);
    yield put({ type: SET_LOADING_ADDRESS_LISTING, data: false });
  }
}
