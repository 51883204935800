import Cookies from 'universal-cookie';
const cookie = new Cookies();
const { remove } = cookie;
const get = function() {
  const cookies = new Cookies();
  return cookies.get(...arguments);
};

const set = function() {
  const cookies = new Cookies();
  return cookies.set(...arguments);
};

let userLoggedIn = false;
export const setLoggedInUser = data => {
  // console.log('Setter for loggedIn service ', data);
  userLoggedIn = !!data;
};
export const isLoggedInUser = () => userLoggedIn;
// export const isLoggedInUser = () => !!get('hbpt');

export default { get, set, remove, isLoggedInUser };
