import {
  RESET_PRODUCTS,
  RESET_SUBSCRIPTION,
  SET_CALENDAR,
  SET_LOADING,
  SET_PRODUCTS,
  SET_SAVED_PAYMENTS,
  SET_SLOT,
  SET_PAYMENT_DETAILS,
  SET_SUMMARY,
  SET_SUBSCRIPTION_CONFIRMATION,
  PUT_SELECTED_DAY,
  PUT_PRODUCTS_AND_PRICE,
  RESET_SLOT,
  SET_WEEKS,
} from './subscription.action';

const initialState = {
  loading: false,
  savedPayments: null,
  calendar: null,
  selectedDay: null,
  subscriptionItems: null,
  summary: null,
  subscriptionId: null,
  selectedSlot: null,
  paymentSummary: null,
  paymentMethods: null,
  subscriptionConfirmationDetails: null,
};

const subscriptionReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CALENDAR:
      return {
        ...state,
        calendar: data,
        subscriptionId: data.subscriptionId,
        selectedSlot: data.selectedSlot,
        loading: false,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        subscriptionItems: data.sections,
        selectedDay: data.selectedDay,
        loading: false,
      };
    case PUT_PRODUCTS_AND_PRICE:
      return {
        ...state,
        subscriptionItems: data.sections,
        selectedDay: data.selectedDay,
        calendar: {
          ...state.calendar,
          strikedPrice: data.strikedPrice,
          finalPrice: data.finalPrice,
          weeks: data.weeks,
        },
        loading: false,
      };
    case SET_WEEKS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          weeks: data.weeks,
        }
      }
    case RESET_PRODUCTS:
      return {
        ...state,
        selectedDay: null,
        subscriptionItems: null,
      };
    case SET_SAVED_PAYMENTS:
      return {
        ...state,
        savedPayments: data,
        loading: false,
      };
    case SET_SLOT:
      return {
        ...state,
        selectedSlot: data,
        loading: false,
      };
    case RESET_SLOT:
      return { ...state, selectedSlot: null };
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_SUMMARY:
      return {
        ...state,
        summary: data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_SUBSCRIPTION_CONFIRMATION:
      return {
        ...state,
        subscriptionConfirmationDetails: data,
      };
    case PUT_SELECTED_DAY:
      return {
        ...state,
        selectedDay: {
          ...state.selectedDay,
          ...data,
        }
      };
    case RESET_SUBSCRIPTION:
      return initialState;
    default:
      return state;
  }
};

export default subscriptionReducer;
