const prefix = 'CART/';
export const GET_CART = `${prefix}GET_CART`;
export const SET_CART = `${prefix}SET_CART`;
export const RESET_CART = `${prefix}RESET_CART`;
export const SET_CART_QTY_COUNT = `${prefix}SET_CART_QTY_COUNT`;
export const POST_ADD_ITEM = `${prefix}POST_ADD_ITEM`;
export const POST_REMOVE_ITEM = `${prefix}POST_REMOVE_ITEM`;
export const POST_PROMO_CODE = `${prefix}POST_PROMO_CODE`;
export const SET_PROMO_CODE = `${prefix}SET_PROMO_CODE`;
export const GET_PROMO_LIST = `${prefix}GET_PROMO_LIST`;
export const SET_PROMO_LIST = `${prefix}SET_PROMO_LIST`;
export const REMOVE_PROMO_CODE = `${prefix}REMOVE_PROMO_CODE`;
export const POST_REFERRAL_CODE = `${prefix}POST_REFERRAL_CODE`;
export const SET_REFERRAL_CODE = `${prefix}SET_REFERRAL_CODE`;
export const REMOVE_REFERRAL_CODE = `${prefix}REMOVE_REFERRAL_CODE`;
export const SET_CART_LOADING = `${prefix}SET_CART_LOADING`;
export const POST_MERGE_CART = `${prefix}POST_MERGE_CART`;
export const REMOVE_SOLDOUT_ITEMS = `${prefix}REMOVE_SOLDOUT_ITEMS`;
export const SCRATCH_CARD = `${prefix}SCRATCH_CARD`;
export const UPDATE_SCRATCH_CARD = `${prefix}UPDATE_SCRATCH_CARD`;
export const SET_CART_SINGLE_SKU = `${prefix}SET_CART_SINGLE_SKU`;
export const SET_CART_SKUS = `${prefix}SET_CART_SKUS`;
export const RESET_CART_SKUS = `${prefix}RESET_CART_SKUS`;

export const showCart = data => ({ type: GET_CART, data });

export const resetCart = () => ({ type: RESET_CART });

export const postAddItem = data => ({ type: POST_ADD_ITEM, data });

export const postRemoveItem = data => ({ type: POST_REMOVE_ITEM, data });

export const getPromoList = () => ({ type: GET_PROMO_LIST });

export const applyPromoCode = data => ({ type: POST_PROMO_CODE, data });

export const removePromoCode = data => ({ type: REMOVE_PROMO_CODE, data });

export const applyReferralCode = data => ({ type: POST_REFERRAL_CODE, data });

export const removeReferralCode = data => ({ type: REMOVE_REFERRAL_CODE, data });

export const setCartLoading = data => ({ type: SET_CART_LOADING, data });

export const mergeOrFlushCart = data => ({ type: POST_MERGE_CART, data });

export const setCartQtyCount = data => ({ type: SET_CART_QTY_COUNT, data });

export const removeSoldoutItems = data => ({ type: REMOVE_SOLDOUT_ITEMS, data });

export const scratchCard = data => ({ type: SCRATCH_CARD, data });
