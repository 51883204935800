import request from 'hb-redux-store/util/request';

export const getHbPointsApi = () => request.get('/api/getHBPointsScreen');

export const getHbEarningHitoryApi = ({
  tab = 'all',
  pageNo = 0,
  pageSize = 10
}) =>
  request.get(
    `/api/getEarningHistory?earningHistoryTab=${tab}&pageNo=${pageNo}&pageSize=${pageSize}`
  );

export const submitReferalApi = data =>
  request.post(`/api/account/referral/apply/${data}`);
