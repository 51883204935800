import { combineReducers } from 'redux';
import initialReducer from './initial.reducer';
import loginReducer from './feature/login/login.reducer';
import productReducer from './feature/products/product.reducer';
import addressReducer from './feature/address/address.reducer';
import cartReducer from './feature/cart/cart.reducer';
import checkoutReducer from './feature/checkout/checkout.reducer';
import homeReducer from './feature/home/home.reducer';
import modalReducer from './feature/modal/modal.reducer';
import accountsReducer from './feature/accounts/accounts.reducer';
import blogReducer from './feature/blog/blog.reducer';
import subscriptionReducer from './feature/subscription/subscription.reducer';
import walletReducer from './feature/wallet/wallet.reducer';
import notificationReducer from './feature/notifications/notifications.reducer';
import hbPointReducer from './feature/halalboxPoints/hbPoint.reducer';
import remoteConfigReducer from './feature/remoteConfig/remoteConfig.reducer';
import hbPrimeReducer from './feature/halalboxPrime/hbPrime.reducer';

const rootReducer = combineReducers({
  initialReducer,
  loginReducer,
  productReducer,
  cartReducer,
  checkoutReducer,
  addressReducer,
  homeReducer,
  modalReducer,
  accountsReducer,
  blogReducer,
  subscriptionReducer,
  walletReducer,
  notificationReducer,
  hbPointReducer,
  remoteConfigReducer,
  hbPrimeReducer
});

export default rootReducer;
