import request from 'hb-redux-store/util/request';

export const getWalletApi = ({source, pageNo = 0, pageSize = 10, rechargeNo = ''}) =>
  request.get(
    `/api/wallet/fetchWalletTransaction?source=${source}&pageNo=${pageNo}&pageSize=${pageSize}&rechargeNo=${rechargeNo}`,
  );

export const addWalletRechargeApi = data =>
  request.post(`/api/wallet/v2/validate?source=${data.source}`, data);

export const getRechargeTransactionNoApi = () =>
  request.post('/api/wallet/recharge');

export const postSaveRechargePaymentApi = ({
  paymentMode,
  data,
}) =>
  request.post(
    `/api/wallet/recharge/payment-methods/v2/${paymentMode}`,
    data,
  ); // COD or POL

export const postVerifyPaymentApiPayU = ({
  rechargeTxnNo,
  data,
}) =>
request.post(
  `/api/wallet/recharge/${rechargeTxnNo}/v2/validate-payment-payu-app`,
  data,
);

export const postVerifyRechargeApi = ({data}) =>
  request.post(
    `/api/wallet/recharge/v2/validate-payment`,
    data,
  );

export const getVerifyPaymentModeApi = () =>
  request.get(`/api/payment/v2/verfiyPaymentMode`);
