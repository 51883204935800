import {
  SET_PDP_DETAILS,
  SET_LOADING_PDP,
  SET_CATEGORY_PLP_DETAILS,
  SET_DEALS,
  SET_CATEGORIES,
  RESET_PDP,
  RESET_PLP,
  RESET_CATEGORIES,
  SET_SEARCH_LIST,
  SET_SECTION_PLP,
  SET_RANDOM_SECTION,
} from './product.action';

const initialState = {
  loadingPdp: false,
  pdpDetails: null,
  categoryPLPDetails: null,
  deals: null,
  randomSection: null,
  categories: null,
  searchSuggestion: [],
};

const productReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_PDP_DETAILS:
      return {
        ...state,
        ...data,
        loadingPdp: false,
      };
    case SET_LOADING_PDP:
      return {
        ...state,
        loadingPdp: data,
      };
    case SET_CATEGORY_PLP_DETAILS:
      return {
        ...state,
        loadingPdp: false,
        categoryPLPDetails: data,
      };
    case SET_SECTION_PLP:
      return {
        ...state,
        loadingPdp: false,
        categoryPLPDetails: data,
      };
    case SET_DEALS:
      return {
        ...state,
        deals: data,
      };
    case SET_RANDOM_SECTION:
      return {
        ...state,
        randomSection: data,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: data,
        loadingPdp: false,
      };
    case RESET_PDP:
      return {
        ...state,
        loadingPdp: false,
        pdpDetails: null,
      };
    case RESET_PLP:
      return {
        ...state,
        categoryPLPDetails: null,
      };
    case RESET_CATEGORIES:
      return {
        ...state,
        categories: null,
      }
    case SET_SEARCH_LIST:
      return {
        ...state,
        searchSuggestion: data,
        loadingPdp: false,
      };
    default:
      return state;
  }
};

export default productReducer;
