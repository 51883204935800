import {
  SET_LAUNCH_INFO,
  SET_APP_SETTINGS,
  SET_NOTIFICATION_MSG,
  SET_MAIN_HEADER,
} from './initial.action';
import { SET_LOGOUT_INFO } from './feature/login/login.action';
import { SET_PROFILE_DETAILS } from './feature/accounts/accounts.action';
import { setLoggedInUser } from "./util/cookieService";

// CONSTANTS
const SNACKBAR_DEFAULT_TIMEOUT = 2500;

const initialState = {
  initialCheckDone: false,
  pingResponse: null,
  settings: {},
  launchInfo: null,
  notification: {
    show: false,
    message: '',
    timeout: SNACKBAR_DEFAULT_TIMEOUT,
    cancellable: true,
  },
  showMainHeader: true,
};

const initialReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_LAUNCH_INFO:
      setLoggedInUser(data.loggedIn);
      return {
        ...state,
        initialCheckDone: true,
        launchInfo: { ...data },
      };
    case SET_LOGOUT_INFO:
      setLoggedInUser(false);
      return {
        ...state,
        loggedIn: false,
      };
    case SET_APP_SETTINGS:
      return {
        ...state,
        settings:{
          ...state.settings,
          ...data,
        }
      };
    case SET_NOTIFICATION_MSG:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...data,
        },
      };
    case SET_PROFILE_DETAILS:
      setLoggedInUser(data.loggedIn || state.launchInfo.loggedIn);
      return {
        ...state,
        launchInfo: {
          ...state.launchInfo,
          ...data
        }
      };
    case SET_MAIN_HEADER:
      return {
        ...state,
        showMainHeader: data,
      };
    default:
      return state;
  }
};

export default initialReducer;
