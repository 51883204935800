const prefix = 'BLOG/';
export const GET_BLOG_BY_SLUG = `${prefix}GET_BLOG_BY_SLUG`;
export const SET_BLOG_BY_SLUG = `${prefix}SET_BLOG_BY_SLUG`;
export const GET_BLOGS_BY_CATEGORY = `${prefix}GET_BLOGS_BY_CATEGORY`;
export const SET_BLOGS_WITH_CATEGORY = `${prefix}SET_BLOGS_WITH_CATEGORY`;
export const GET_BLOGS_BY_SEGMENT = `${prefix}GET_BLOGS_BY_SEGMENT`;
export const SET_BLOGS_BY_SEGMENT = `${prefix}SET_BLOGS_BY_SEGMENT`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const RESET_BLOG_DETAILS = `${prefix}RESET_BLOG_DETAILS`;

export const getBlogBySlug = data => ({ type: GET_BLOG_BY_SLUG, data });

export const resetBlogDetails = data => ({ type: RESET_BLOG_DETAILS, data });

export const getBlogsByCategory = data => ({
  type: GET_BLOGS_BY_CATEGORY,
  data
});

export const getBlogsBySegment = data => ({ type: GET_BLOGS_BY_SEGMENT, data });
