import {
  RESET_SHIPMENT,
  SET_CHECKOUT_LOADING,
  SET_DELIVERY_SLOTS,
  SET_ORDER_CONFIRMATION,
  SET_PAYMENT_DETAILS,
  SET_PG_CUSTOMER_ID,
  SET_PARTIAL_PAYMENT,
  RESET_ORDER_CONFIRMATION,
  SET_PRODUCTS_AND_DELIVERY_SLOT,
} from './checkout.action';
// import { getRandomInteger } from 'hb-redux-store/util/miscellaneousServices';

const initialState = {
  checkoutLoading: false,
  summary: null,
  paymentMethods: null,
  pgCid: null,
  shipments: null,
  isPartialPayment: true,
  productsData: null
};

const checkoutReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_CHECKOUT_LOADING:
      return {
        ...state,
        checkoutLoading: data,
      };
    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        ...data,
        checkoutLoading: false,
      };
    case SET_PG_CUSTOMER_ID:
      return {
        ...state,
        pgCid: data,
      };
    case SET_DELIVERY_SLOTS:
      return {
        ...state,
        shipments: data,
        checkoutLoading: false
      };
    case RESET_SHIPMENT:
      return {
        ...state,
        shipments: null,
      };
    case SET_ORDER_CONFIRMATION:
      return {
        ...state,
        orderConfirmationDetails: data,
        checkoutLoading: false,
      };
    case SET_PARTIAL_PAYMENT:
      return {
        ...state,
        isPartialPayment: data,
      };
    case RESET_ORDER_CONFIRMATION:
      return {
        ...state,
        orderConfirmationDetails: {},
      };
    case SET_PRODUCTS_AND_DELIVERY_SLOT:
      return {
        ...state,
        productsData: data,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
