const prefix = 'LOGIN/';
export const SET_USER = `${prefix}SET_USER`;
export const GET_USER = `${prefix}GET_USER`;
export const POST_LOGOUT = `${prefix}POST_LOGOUT`;
export const SET_LOGOUT_INFO = `${prefix}SET_LOGOUT_INFO`;
export const SET_ERROR_MSG = `${prefix}SET_API_ERROR_MSG`;
export const POST_PHONE_NO_FOR_OTP = `${prefix}POST_PHONE_NO_FOR_OTP`;
export const POST_VERIFY_OTP = `${prefix}POST_VERIFY_OTP`;
export const POST_EMAIL_LOGIN = `${prefix}POST_EMAIL_LOGIN`;
export const POST_VERIFY_CCA_LOGIN = `${prefix}POST_VERIFY_CCA_LOGIN`;
export const SET_CCA_LOGIN_MSG = `${prefix}SET_CCA_LOGIN_MSG`;
export const SET_VISIBLE_BLOCK_FOR_LOGIN = `${prefix}SET_VISIBLE_BLOCK_FOR_LOGIN`;
export const RESET_LOGIN = `${prefix}RESET_LOGIN`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const FORGOT_PASSWORD = `${prefix}FORGOT_PASSWORD`;
export const GET_USER_EMAIL = `${prefix}GET_USER_EMAIL`;
export const SET_USER_EMAIL = `${prefix}SET_USER_EMAIL`;
export const RESET_PASSWORD = `${prefix}RESET_PASSWORD`;
export const SET_LOGIN_DISABLED = `${prefix}SET_LOGIN_DISABLED`;
export const ACTIVE_LOGIN_DISABLED = `${prefix}ACTIVE_LOGIN_DISABLED`;

export const setVisibleBlockForLogin = data => ({
  type: SET_VISIBLE_BLOCK_FOR_LOGIN,
  data,
});

export const setErrorMsg = data => ({
  type: SET_ERROR_MSG,
  data,
});

export const postPhoneNoForOTP = (data, cb) => ({
  type: POST_PHONE_NO_FOR_OTP,
  data,
  cb,
});

export const postVerifyOTP = (data, cb) => ({
  type: POST_VERIFY_OTP,
  data,
  cb
});

export const postEmailLogin = (data, cb) => ({
  type: POST_EMAIL_LOGIN,
  data,
  cb
});

export const postLogout = cb => ({
  type: POST_LOGOUT,
  cb,
});

export const forgotPassword = (data) => ({
  type: FORGOT_PASSWORD,
  data,
});

export const resetPassword = (data, cb) => ({
  type: RESET_PASSWORD,
  data,
  cb,
});

export const getUserEmail = data => ({
  type: GET_USER_EMAIL,
  data,
});

export const resetLoginScreen = () => ({ type: RESET_LOGIN });

export const verifyCCAForOfc = data => ({ type: POST_VERIFY_CCA_LOGIN, data });

export const activeLoginDisabled = data => ({ type: ACTIVE_LOGIN_DISABLED, data });
