import { takeEvery } from 'redux-saga/effects';
import {
  GET_HALALBOX_POINT_DATA,
  GET_HALABOX_EARNING_HISTORY,
  SUBMIT_REFERAL_CODE,
} from './hbPoint.action';
import {
  getHbPointDataSaga,
  getHbPointEarningHistorySaga,
  submitReferalCodeSaga,
} from './hbPoint.saga';

export const HalalBoxPointSaga = [
  takeEvery(GET_HALALBOX_POINT_DATA, getHbPointDataSaga),
  takeEvery(GET_HALABOX_EARNING_HISTORY, getHbPointEarningHistorySaga),
  takeEvery(SUBMIT_REFERAL_CODE, submitReferalCodeSaga),
];
