import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
// import ReactGA from 'react-ga';
import './index.scss';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './appConfig';
import ScrollToTop from 'components/common/ScrollToTop';

/** redux */
import { Provider } from 'react-redux';
import configureStore from 'hb-redux-store/store';
import rootSaga from 'hb-redux-store/root.saga';
import history from 'hb-redux-store/history';
import { getDeviceType } from 'hb-redux-store/util/DeviceDetectionService';
import { DEVICE_TYPE } from './hb-redux-store/util/constantServices';
// import WebApp from './WebApp';
import ErrorBoundary from './components/common/ErrorBoundary/ErrorBoundary';
import Loader from './components/mweb/Skeletons/Loader/Loader';
import AnalyticsService from './AnalyticsService';

const store = configureStore();
store.runSaga(rootSaga);

const deviceType = getDeviceType();
console.log('DeviceType: ', deviceType);
const WebComponent = React.lazy(() => import('./WebApp'));
const MobileComponent = React.lazy(() => import('./App'));
const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'UA-176757642-1';
AnalyticsService.initGA(GA_ID);
// AnalyticsService.initGTM();
ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          {deviceType === DEVICE_TYPE.DESKTOP ? (
            <ScrollToTop>
              <WebComponent />
            </ScrollToTop>
          ) : (
            <MobileComponent />
          )}
        </Suspense>
      </ErrorBoundary>
    </Provider>
  </Router>,
  document.getElementById('root')
);

export { store };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(config.swConfig);
