import { takeEvery } from 'redux-saga/effects';
import {
  GET_ADDRESS_LISTING,
  SET_DEFAULT_ADDRESS,
  DELETE_ADDRESS,
} from './address.action';
import {
  getAddressListingSaga,
  setDefaultAddressSaga,
  deleteAddressSaga,
} from './address.saga';

export const addressSaga = [
  takeEvery(GET_ADDRESS_LISTING, getAddressListingSaga),
  takeEvery(SET_DEFAULT_ADDRESS, setDefaultAddressSaga),
  takeEvery(DELETE_ADDRESS, deleteAddressSaga),
];
