import { call, put, delay } from 'redux-saga/effects';
import {
  SET_MARK_AS_READ_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_LOADING,
  SET_READ_NOTIFICATION
} from './notifications.action';
import { getNotificationsApi, postMarkAsReadNotificationsApi } from './notifications.api';
import { MARK_NOTIFICATION_AS_READ_DELAY } from '../../util/constantServices';
// import history from '../../history';
// import config from 'appConfig';

export function* getNotificationsSaga({ data = {} }) {
  try {
    yield put({ type: SET_NOTIFICATIONS_LOADING, data: true });
    const { data: notifications } = yield call(getNotificationsApi);
    if (data.successCb) data.successCb(notifications);
    yield put({ type: SET_NOTIFICATIONS, data: notifications, });
  } catch (e) {
    console.log('Err@ getNotificationsSaga: ', e);
    yield put({ type: SET_NOTIFICATIONS_LOADING, data: false });
    if (data.errorCb) data.errorCb(e);
  }
}

export function* postMarkAsReadNotificationSaga({ data }) {
  try {
    yield put({ type: SET_NOTIFICATIONS_LOADING, data: true });
    const { data: notifications } = yield call(
      postMarkAsReadNotificationsApi,
      data
    );
    if (data.successCb) data.successCb(notifications);
    if(notifications){
      yield put({
        type: SET_MARK_AS_READ_NOTIFICATIONS,
        data: 0
      });
    }
    yield delay(MARK_NOTIFICATION_AS_READ_DELAY);
    yield put({
      type: SET_READ_NOTIFICATION,
    });
  } catch (e) {
    console.log('Err@ postMarkAsReadNotificationSaga: ', e);
    yield put({ type: SET_NOTIFICATIONS_LOADING, data: false });
    if (data.errorCb) data.errorCb(e);
  }
}
