import { takeEvery } from 'redux-saga/effects';
import {
  GET_CATEGORY_PLP_DETAILS,
  GET_DEALS,
  GET_PDP_DETAILS,
  GET_CATEGORIES,
  GET_SEARCH_LIST,
  SET_NOTIFY_USER,
  GET_COMBO_PDP_DETAILS,
  GET_SECTION_PLP,
  GET_RANDOM_SECTION,
} from './product.action';
import {
  getCategoryPLPDetailSaga,
  getDealsSaga,
  getPDPDetailSaga,
  getCategoriesSaga,
  getSearchProductsSaga,
  notifyUserSaga,
  getComboPDPDetailSaga,
  getSectionPLPSaga,
  getRandomSectionSaga,
} from './product.saga';

export const productSaga = [
  takeEvery(GET_PDP_DETAILS, getPDPDetailSaga),
  takeEvery(GET_COMBO_PDP_DETAILS, getComboPDPDetailSaga),
  takeEvery(GET_CATEGORY_PLP_DETAILS, getCategoryPLPDetailSaga),
  takeEvery(GET_DEALS, getDealsSaga),
  takeEvery(GET_CATEGORIES, getCategoriesSaga),
  takeEvery(GET_SEARCH_LIST, getSearchProductsSaga),
  takeEvery(SET_NOTIFY_USER, notifyUserSaga),
  takeEvery(GET_SECTION_PLP, getSectionPLPSaga),
  takeEvery(GET_RANDOM_SECTION, getRandomSectionSaga),
];
