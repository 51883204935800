import {call, put, select} from 'redux-saga/effects';
import config from 'appConfig';
import { getAllMembershipDetailApi, getMembershipBannerDetailApi, addMembershipTocartApi, removeMembershipFromCartApi } from './hbPrime.api';
import {showCartSaga} from 'hb-redux-store/feature/cart/cart.saga'
import {
  SET_LOADING,
  SET_MEMBERSHIP_DETAIL,
  SET_MEMBERSHIP_BANNER_DETAIL
} from './hbPrime.action';
import { SET_NOTIFICATION_MSG } from 'hb-redux-store/initial.action';
import {
  SET_NON_SERVICEABLE_MODAL,
} from 'hb-redux-store/feature/modal/modal.action';
import { selectInitialData } from 'hb-redux-store/initial.selectors';
import history from 'hb-redux-store/history'
import { LOGIN_ERROR_CODE } from 'hb-redux-store/constants/halalboxPrime';
import { setItem } from 'hb-redux-store/util/localStorageService';

export function* getAllMembershipDetailSaga() {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data} = yield call(getAllMembershipDetailApi);
    yield put({type: SET_MEMBERSHIP_DETAIL, data});
  } catch (e) {
    console.log('Err@ getAllMembershipDetailSaga: ', e);
    yield put({type: SET_LOADING, data: false});
  }
}

export function* getMembershipBannerDetailSaga() {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data} = yield call(getMembershipBannerDetailApi)
    // console.log("data check in mem ban : ",data);
    yield put({type: SET_MEMBERSHIP_BANNER_DETAIL, data})
  } catch (e) {
    console.log('Err@ getMembershipBannerDetailSaga: ', e);
    yield put({type: SET_LOADING, data: false});
  }
}

export function* addMembershipTocartSaga({data}) {
  try {
    console.log("addMembershipTocartSaga : ",data);
    yield put({type: SET_LOADING, data: true});
    const {data:resp} = yield call(addMembershipTocartApi,data?.api)
    if(data.successCb && resp === 'success') data.successCb(resp)
    if(!data?.api?.isBuyNow) yield call(showCartSaga)
    yield put({type: SET_LOADING, data: false});
  } catch (e) {
    console.log('Err@ addMembershipTocartSaga: ', e);
    const {launchInfo} = yield select(selectInitialData);
    yield put({type: SET_LOADING, data: false});
    let errorMessage = 'Unable To Add Membership in Cart';
    if (e.data?.description || e.data?.error?.errorMessage || e?.error?.errorMessage) {
      errorMessage = e.data?.description || e.data?.error?.errorMessage || e?.error?.errorMessage
    }
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMessage,
      },
    });
    const redirectToLogin = !!errorMessage && e?.error?.errorCode === LOGIN_ERROR_CODE && !launchInfo?.loggedIn && data?.api?.isBuyNow;
    if (config.isApp && redirectToLogin) {
      data.failOpCb && data.failOpCb({error: e?.error || {}});
    }
    if (redirectToLogin && !config.isApp && data?.api?.isBuyNow) {
      const buyNowTimer = new Date().getTime();
      setItem('isBuyNow', buyNowTimer);
      history.push(`/login?redirectUrl=halalbox-select`);
    }
    if (errorMessage === 'Please select a location.') {
      yield put({
        type: SET_NON_SERVICEABLE_MODAL,
        data: {visible: true, source: 'cart'},
      });
    }
  }
}
export function* removeMembershipFromCartSaga ({data}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data:resp} = yield call(removeMembershipFromCartApi, data?.api)
    if(data.successCb && resp === 'success') data.successCb(resp)
    yield call(showCartSaga)
    yield put({type: SET_LOADING, data: false});
  } catch (e) {
    console.log('Err@ removeMembershipFromCartSaga: ', e);
    yield put({type: SET_LOADING, data: false});
    if (e?.data?.description) {
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: {
          show: true,
          message: e?.data?.description || 'Unable To Remove Membership From Cart',
        },
      });
    }
  }
}
