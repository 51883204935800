export const RUPEE_SYMBOL = '₹';

export const LOGIN_SCREEN_STATES = {
  PHONE_NO: 'PHONE_NO',
  OTP: 'OTP',
  EMAIL: 'EMAIL',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
};
export const LOGIN_SCREEN_CTA = {
  LOGIN: 'LOGIN',
  CONTINUE: 'CONTINUE',
  SUBMIT: 'SUBMIT',
};
export const DEVICE_TYPE = {
  'DESKTOP': 'desktop',
  'MWEB': 'mweb',
  'OTHERS': 'others',
};

export const HOMEPAGE_SECTIONS = {
  BANNER_CUSTOM_TILES: 'BANNER_CUSTOM_TILE',
  CATEGORIES: 'CATEGORIES',
  DEALS_OF_THE_DAY: 'DEALS_OF_THE_DAY',
  PRE_ORDER: 'PRE_ORDER',
  BLOGS: 'BLOGS',
  USP: 'USP',
  BESTSELLERS: 'BESTSELLERS',
  CUSTOM_TILE: 'CUSTOM_TILE',
  NEWS: 'NEWS',
  REFER_N_EARN: 'REFER_N_EARN',
  COMBO_PRODUCTS: 'COMBO_PRODUCTS',
  RECENTLY_VIEWED: 'RECENTLY_VIEWED',
  ATC_AND_CART_ABANDONED: 'ATC_AND_CART_ABANDONED',
  RECENTLY_PURCHASED: 'RECENTLY_PURCHASED',
};

// export const ACTION_TYPE_DETAILS = {
//   PDP: {
//     type: 'PDP',
//     path: '/{categoryName}/{productName}',
//     getUri: actionValue => `/category-name/${actionValue}`,
//   },
//   PLP: {
//     type: 'PLP',
//     path: '/{category}',
//     getUri: actionValue => `/${actionValue}`,
//   },
//   noAction: {
//     type: 'noAction',
//     path: '#',
//     getUri: () => '#'
//   }
// };

export const PAYMENT_GATEWAYS = {
  RAZORPAY: { id: 1 },
  PAYU: { id: 2 },
};

export const POINTS_FILTER = [
  {pid: 0, title: 'ALL', value: 'all'},
  {pid: 1, title: 'Pending Referral', value: 'pending'},
];

export const ATC_VIBRATE_PATTERN = [100, 130, 100];

export const SOURCE = {
  ACCOUNTS: 'accounts',
  RECHARGE_FOR_ORDER: 'rechargeForOrder',
  RECHARGE: 'recharge',
};

export const CHECKOUT_FAILURE_REDIRECT = {
  ORDER_FAILURE: 'order_failure',
  CART: 'cart',
  SHIPMENT: 'shipment',
  PG_NOT_LOADED: 'pg_not_loaded',
  SHOW_PG_INFO_POPUP: 'SHOW_PG_INFO_POPUP',
  MEMBERSHIP_LANDING_SCREEN: 'membershipLandingScreen',
};

export const CHECKOUT_STEPS = {
  PLACE_ORDER: 'placeOrder',
  PG_NOT_LOADED: 'pg_not_loaded',
  PAYMENT_VERIFICATION: 'pgVerify',
};

export const SUBSCRIPTION_FAILURE_REDIRECT = {
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_SUMMARY: 'subscriptionSummary',
  ORDER_FAILURE: 'order_failure',
  PG_NOT_LOADED: 'pg_not_loaded',
  SHOW_PG_INFO_POPUP: 'SHOW_PG_INFO_POPUP',
};

export const SUBSCRIPTION_STEPS = {
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_SUMMARY: 'subscriptionSummary',
  PG_NOT_LOADED: 'pg_not_loaded',
  PAYMENT_VERIFICATION: 'pgVerify',
};

export const ORDER_TRACKING_STATUS = [
  {
    position: 0,
    key: 'ORDER_CONFIRMED',
    icon: 'icon-status-order-packed',
    message: 'Order Confirmed',
  },
  {
    position: 1,
    key: 'ORDER_LOGISTICS_DRIVER_ASSIGNED',
    icon: 'icon-status-pickup-assigned',
    message: 'Delivery Boy Assigned',
  },
  {
    position: 2,
    key: 'ORDER_LOGISTICS_PICKED_UP',
    icon: 'icon-status-out-for-delivery',
    message: 'Out for Delivery',
    showCall: true,
  },
  {
    position: 3,
    key: 'ORDER_LOGISTICS_DELIVERED',
    icon: 'icon-status-delivered',
    message: 'Order Delivered',
  },
];

export const ORDER_STATUS_TO_POLL = [
  'ORDER_CONFIRMED',
  'ORDER_LOGISTICS_DRIVER_ASSIGNED',
  // 'ORDER_LOGISTICS_PICKED_UP/OUT_FOR_DELIVERY',
  'ORDER_LOGISTICS_PICKED_UP',
];

// https://www.w3resource.com/javascript/form/email-validation.php
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

export const NAME_REGEX = /^[A-Za-z][A-Za-z\s]*$/;

export const HIGHLIGHT_REGEX = /[ {{\}}]/;
export const EXTRACT_INTERPOLATION_TEXT = /{{(.*?)}}/g;

export const CUSTOMER_TYPE = {
  'NEW_USER': 'NU',
  'REPEAT_USER': 'RU',
  'POWER_USER': 'PU',
};

export const CP_MAPPING_KEY = 'CP_MAP';

export const GOOGLE_GENERIC_REVIEW = {
  "title": "What Our Customers Have to Say",
  "reviews": [
    {
      "imageURL": "https://ik.imagekit.io/iwcam3r8ka/prod/testimonies/202309/ff6aeec8-17ef-480c-a2b8-fa5bf7bdad25.jpg",
      "reviewTitle": "VegXL is Trustworthy Brand which is being true to its tagline: Grade A quality. We've been Ordering dry fruits and groceries from VegXL for a quiet some time now and never felt the need to follow up with them OR had ever any complaints a of now. Pros: Quality Meat, Sealed Packaging, Halal, Hassle Free Ordering Option on App and Website. Cons: Location of deliveries are limited",
      "reviewerName": "Parvati Bhandari"
    },
    {
      "imageURL": "https://ik.imagekit.io/iwcam3r8ka/prod/testimonies/202309/acb95c28-ceab-4c5f-a723-d7ebf3f2a909.jpg",
      "reviewTitle": "VegXL is a great place to get Grade A quality quality groceries, that too online with early morning home delivery service.. and the service guys are also nice with smiling faces but the customer service is very slow.. As the tag line suggest... Its A+ quality & super fresh.",
      "reviewerName": "Dr. Prasad Kamble"
    },
    {
      "imageURL": "https://ik.imagekit.io/iwcam3r8ka/prod/testimonies/202309/1305d725-b749-4fe9-9b68-0366ada52196.jpg",
      "reviewTitle": "Being a premium hotel chain, we need consistent quality of vegetable especially exotic veggies and premium dryfruits. And VegXL has never let us down. 👍",
      "reviewerName": "Mint Vivaanta (BKC)"
    },
    {
      "imageURL": "https://ik.imagekit.io/iwcam3r8ka/prod/testimonies/202309/dcaa0b83-3512-4593-b0f3-4ec4a480fe25.jpg",
      "reviewTitle": "Folks at VegXL are very particular about their quality. Their grievance resolution team is so awesome and polite. ",
      "reviewerName": "Lalan Lokhande"
    },
    {
      "imageURL": "https://ik.imagekit.io/iwcam3r8ka/prod/testimonies/202309/e5a70eb9-0d47-4a0d-860e-b385f25b18f4.jpg",
      "reviewTitle": "Reasonably priced and Quality groceries and fruits, right at my doorstep. The slight delay doesn't matter to us as I Plan my order in advance considering Mumbai Traffic and climate which could not be the reason to give any less stars than 5 because of the service and Quality. Keep up the Good Work!",
      "reviewerName": "Rajeev Biswas"
    },
    // {
    //   "imageURL": "https://lh3.googleusercontent.com/-Kct0Tv9Ctm4/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckBqcCiKkmxAk3Jn3bAqYUMFaASpQ/s50-c-c0x00000000/photo.jpg",
    //   "reviewTitle": "I ordered prawns from Halalbox and it was fully cleaned and deveined. The prawns were very fresh and the pieces had good size. The quantity was also very good compared to the other app delivery I have ordered before. I am happy in covid corono times I am getting all my non veg food ordered and delivered in my home.",
    //   "reviewerName": "gati biswas"
    // },
    // {
    //   "imageURL": "https://scontent.famd1-2.fna.fbcdn.net/v/t1.0-1/s480x480/90267593_206960093962571_5851019258552647680_n.jpg?_nc_cat=104&ccb=2&_nc_sid=7206a8&_nc_ohc=OyfrPBs0xCwAX9C1nzJ&_nc_ht=scontent.famd1-2.fna&tp=7&oh=a4ca95f500d58de5ca424378c7c0bdeb&oe=5FC643E2",
    //   "reviewTitle": "I'm a learner and it is difficult for me to clean and cut the meat into pieces anyway, it's very relieving that Halalbox sends the mutton pieces finely chopped and cleaned, I just need to wash and start cooking without hassles.",
    //   "reviewerName": "Saumya Sharma"
    // },
    // {
    //   "imageURL": "https://scontent.famd1-1.fna.fbcdn.net/v/t1.0-1/c0.0.480.480a/p480x480/70900264_787952884957116_8999015409306828800_n.jpg?_nc_cat=110&ccb=2&_nc_sid=7206a8&_nc_ohc=0ehvMCDAUhAAX8CpMhp&_nc_ht=scontent.famd1-1.fna&tp=27&oh=754ae54dcc0c519baa7f1c6e6f4aa15d&oe=5FC757F5",
    //   "reviewTitle": "During a pandemic we avoid going out. Due to which we did not eat meat for almost 6 months. Came across Halal box while surfing on the internet, we like the quality and their fast delivery.",
    //   "reviewerName": "Uday Sindhi"
    // },
    // {
    //   "imageURL": "https://scontent.famd1-3.fna.fbcdn.net/v/t1.0-1/p480x480/123178497_969133590245172_6703750693485386076_n.jpg?_nc_cat=106&ccb=2&_nc_sid=7206a8&_nc_ohc=UInWR4IkfjoAX86GkIZ&_nc_ht=scontent.famd1-3.fna&tp=6&oh=fd25d832041ca53c3397e3ddbbb3ae40&oe=5FC7B054",
    //   "reviewTitle": "It's amazing to find out Halalbox service, their quality is simply unmatched. I like to cook non veg dishes on the weekends, and I can relax and be assured that the meat will be of good quality, and delivered on time safely to me.",
    //   "reviewerName": "Vaibhav Tripathi"
    // },
    // {
    //   "imageURL": "https://scontent.famd1-3.fna.fbcdn.net/v/t1.0-1/c0.0.480.480a/p480x480/84000146_103870594530108_514529961005023232_o.jpg?_nc_cat=109&ccb=2&_nc_sid=7206a8&_nc_ohc=EbucOhLX5ewAX_4qwca&_nc_ht=scontent.famd1-3.fna&tp=27&oh=24702be7ba1b6afa956be48259e7105c&oe=5FC67341",
    //   "reviewTitle": "Halalbox always provides me with fresh fishes, be it Prawns, Bombil or Surmai, I can always be sure that they will deliver them in premium quality, hygienically packed. The delivery men are polite and prompt as well.",
    //   "reviewerName": "Parwati Bhandari"
    // },
  ]
};

export const SECTIONS_PLP = {
  PREORDER: {
    title: 'Preorder Products',
    subTitle: 'Pre-order exotic products from wide variety.',
    apiEndpoint: '/api/preOrder',
  }
};

export const PAYMENT_PENDING = { result: 'failed', reason: 'pending' };

export const MARK_NOTIFICATION_AS_READ_DELAY = 5000;

export const HALALBOX_INDIA_CC_NUMBER = 6262474711;

export const DEFAULT_REFERRAL_AMOUNT = 200;
