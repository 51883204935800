import {
  SET_LOADING,
  SET_RECHARGE_METHODS,
  SET_TRANSACTION_HISTORY,
  SET_WALLET,
  UPDATE_WALLET_TRANSACTION,
} from './wallet.action';

const initialState = {
  loading: false,
  walletData: {},
  rechargeMethods: null,
  walletTransactionHistory: [],
};

const walletReducer = (state = initialState, {type, data}) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_WALLET:
      return {
        ...state,
        walletData: data,
        loading: false,
      };
    case SET_RECHARGE_METHODS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case UPDATE_WALLET_TRANSACTION:
      return {
        ...state,
        loading: false,
        walletTransactionHistory: [...state.walletTransactionHistory, ...data],
      };
    case SET_TRANSACTION_HISTORY:
      return {
        ...state,
        walletTransactionHistory: [],
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default walletReducer;
