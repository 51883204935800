import { all, call, put } from 'redux-saga/effects';
import { getHbPointsApi, getHbEarningHitoryApi, submitReferalApi } from './hbPoint.api';
import {
  SET_LOADING,
  SET_HALALBOX_POINT_DATA,
  SET_HALABOX_EARNING_HISTORY,
  UPDATE_EARNING_HISTORY,
  RESET_EARNING_HISTORY
} from './hbPoint.action';
import { getReferralListingApi } from '../accounts/accounts.api';

export function* getHbPointDataSaga() {
  try {
    yield put({type: SET_LOADING, data: true});
    const promises = [call(getHbPointsApi), call(getReferralListingApi)];
    const [hbPointsResp, referralResp] = yield all(promises);

    yield put({
      type: SET_HALALBOX_POINT_DATA,
      data: {
        hbPointData: hbPointsResp?.data,
        referralGuides: referralResp?.data?.referralGuides,
      }
    });
  } catch (e) {
    console.log('Err@ getHbPointDataSaga: ', e);
    yield put({type: SET_LOADING, data: false});
  }
}

export function* getHbPointEarningHistorySaga({data}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: resp} = yield call(getHbEarningHitoryApi, data);
    if(data?.pageNo === 0){
      yield put({type: RESET_EARNING_HISTORY});
    }
    yield put({type: UPDATE_EARNING_HISTORY, data: resp?.earn || []});
    yield put({type: SET_HALABOX_EARNING_HISTORY, data: resp});
  } catch (e) {
    console.log('Err@ getHbPointEarningHistorySaga: ', e);
    yield put({type: SET_LOADING, data: false});
  }
}
export function* submitReferalCodeSaga({data,cb}) {
  try {
   yield put({type: SET_LOADING, data: true});
   const {data:resp} = yield call(submitReferalApi,data?.refCode);
   cb(resp?.description || '')
   yield put({type: SET_LOADING, data: false});
 } catch (e) {
   console.log('Err@ submitReferalCodeSaga: ', e);
   yield put({type: SET_LOADING, data: false});
   cb(e?.data?.description || '')
 }
 }
