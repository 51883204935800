import React from 'react';
import './badge.scss';

const Badge = ({ text }) => {
  const length = `${text}`.length;
  let count = text;
  if (length < 2) {
    count = `0${count}`;
  }
  return <span className="badge">{count}</span>;
};

export default Badge;
