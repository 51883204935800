import {
  SET_LOADING,
  SET_HALALBOX_POINT_DATA,
  SET_HALABOX_EARNING_HISTORY,
  UPDATE_EARNING_HISTORY,
  RESET_EARNING_HISTORY
} from './hbPoint.action';

const initialState = {
  loading: false,
  hbPointData: [],
  hbPointEarnHistory: [],
  earningHistory: [],
  referralGuides: [],
};

const hbPointReducer = (state = initialState, {type, data}) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_HALALBOX_POINT_DATA:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_HALABOX_EARNING_HISTORY:
      return {
        ...state,
        hbPointEarnHistory:data,
        loading: false,
      };
    case UPDATE_EARNING_HISTORY:
      return {
        ...state,
        loading: false,
        earningHistory:  [...state.earningHistory, ...data],
      };
    case RESET_EARNING_HISTORY:
      return {
        ...state,
        loading: false,
        earningHistory: [],
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default hbPointReducer;
