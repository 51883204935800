import request from 'hb-redux-store/util/request';

export const getAllMembershipDetailApi = () => request.get('/api/getAllMembershipDetailOption');

export const getMembershipBannerDetailApi = () => request.get('/api/getMembershipBannerDetail');

export const addMembershipTocartApi = data => request.post('/api/addMembershipToCart', data)

export const removeMembershipFromCartApi = data => request.del('/api/deleteMembershipFromCart', data)

