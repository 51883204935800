import { takeEvery } from 'redux-saga/effects';
import {
  GET_SAVED_PAYMENTS,
  GET_CALENDAR,
  POST_SLOT,
  GET_PRODUCTS,
  POST_ADD_ITEM,
  POST_REMOVE_ITEM,
  GET_PAYMENT_DETAILS,
  GET_SUMMARY,
  POST_PLACE_ORDER,
  GET_SUBSCRIPTION_CONFIRMATION,
} from './subscription.action';
import {
  getSavedPaymentsSaga,
  getSubscriptionCalendarSaga,
  postSlotSaga,
  getSubscriptionItemsSaga,
  postAddSubscriptionItemSaga,
  postRemoveSubscriptionItemSaga,
  getSubscriptionPaymentDetailsSaga,
  postPlaceOrderSaga,
  getSubscriptionSummarySaga,
  getSubscriptionConfirmationSaga,
} from './subscription.saga';

export const SubscriptionSaga = [
  takeEvery(GET_SAVED_PAYMENTS, getSavedPaymentsSaga),
  takeEvery(GET_CALENDAR, getSubscriptionCalendarSaga),
  takeEvery(POST_SLOT, postSlotSaga),
  takeEvery(GET_PRODUCTS, getSubscriptionItemsSaga),
  takeEvery(POST_ADD_ITEM, postAddSubscriptionItemSaga),
  takeEvery(POST_REMOVE_ITEM, postRemoveSubscriptionItemSaga),
  takeEvery(GET_PAYMENT_DETAILS, getSubscriptionPaymentDetailsSaga),
  takeEvery(POST_PLACE_ORDER, postPlaceOrderSaga),
  takeEvery(GET_SUMMARY, getSubscriptionSummarySaga),
  takeEvery(GET_SUBSCRIPTION_CONFIRMATION, getSubscriptionConfirmationSaga),
];
