const _loadRazorpayScript = () => {
  if (!window.Razorpay) {
    let script = document.createElement('script');
    script.onload = () => {
      console.log('RazrPay loaded');
    };
    script.src = 'https://checkout.razorpay.com/v1/razorpay.js';
    document.head.appendChild(script);
  }
};

function _getRazorPayInstance() {
  if (window.Razorpay) {
    const razorpay = new window.Razorpay({
      key: process.env.REACT_APP_RAZORPAY_KEY || 'rzp_test_nMZ60JGzjG1aV7',
      image:
        // 'https://ik.imagekit.io/iwcam3r8ka/random/202005/b440faf6-3cd7-4859-a30e-a8b7a1a554fd.png',
        'https://vegexcel.com/assets/favicon-192.png'
    });
    console.log('Got RazorPayInstance: ', !!razorpay);
    return razorpay;
  }
  return null;
}

function _makePayment(data) {
  const { razorPayData, razorPaySuccessCb, razorPayErrorCb } = data;
  const razorPayInstance = _getRazorPayInstance();
  razorPayInstance.on('payment.success', razorPaySuccessCb);
  razorPayInstance.on('payment.error', razorPayErrorCb);
  console.log('Calling createPayment using RazorPayData: ', razorPayData);
  const razorPayPopup = razorPayInstance.createPayment(razorPayData);
  return razorPayPopup;
}

export default {
  initRazorpay: _loadRazorpayScript,
  getRazorPayInstance: _getRazorPayInstance,
  makePayment: _makePayment,
};
