import request from 'hb-redux-store/util/request';
// import { getSearchProductResponse } from 'hb-redux-store/util/mockApiService';

export const getPDPDetailsApi = productId => request.get(`/api/product/${productId}`);

export const getComboPDPDetailsApi = cpId => request.get(`/api/comboProduct/${cpId}`);
    // .then(resp => resp)
    // .catch(e => getPDPDetailsResponse(productId));

export const getCategoryPLPDetailsApi = ({
  categoryId,
  isProvisional = false
}) => request.get(`/api/products/${categoryId}?isProvisional=${isProvisional}`);
    // .then(resp => resp)
    // .catch(e => getCategoryPLPDetailsResponse(categoryId));

export const getDealsApi = () => request.get('/api/sectionType/dealsOfTheDay');
    // .then(resp => resp)
    // .catch(e => getDealsResponse());

export const getCategoriesApi = () => request.get('/api/category');
    // .then(resp => resp)
    // .catch(e => ({ data: getCategoriesResponse() }));

export const getSearchProductsApi = searchKey =>
  request
    .get(`/api/productSearch/${searchKey}`)
    // .then(resp => resp)
    // .catch(e => ({ data: getSearchProductResponse() }));

export const notifyUserApi = (sku) =>
  request.get(`/api/${sku}/notify`);

export const getBestsellersApi = () => request.get('/api/sectionType/dealsOfTheDay');

export const getRecentlyViewedApi = () => request.get('/api/recentlyViewdProducts');

export const getRecentlyPurchasedApi = () => request.get('/api/recentlyPurchased');

export const getYouMayLikeApi = () => request.get('/api/wishListProducts');
