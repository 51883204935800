import {
  SET_FETCH_REMOTE_CONFIG,
} from './remoteConfig.action';

const initialState = {
  isRemoteConfigFetched: false,
};

const remoteConfigReducer = (state = initialState, {type, data}) => {
  switch (type) {
    case SET_FETCH_REMOTE_CONFIG:
      return {
        isRemoteConfigFetched: data,
      };
    default:
      return state;
  }
};

export default remoteConfigReducer;
